import React from 'react'
import RecommendedCause from "./cause/RecommendedCause";
import RecommendedProject from "./project/component/recommendation/RecommendedProject";

class RightPanel extends React.Component {
    render() {
        return <div className="col-md-4">
            <div className="rightpanel">
                <div className="rightpanelinn">
                    {/* <RecommendedCause/> */}
                    <RecommendedProject/>
                </div>
            </div>
        </div>
    }
}

export default RightPanel;