import React from "react";
import { authService } from "../auth";
import { convertCurrencySystem, redirectToProfile } from "../commons/CommonFunctions";
import { getFormattedDate, getOptimizedImage } from "./DonationCommonFunction";
import MutualConnect from "../commons/MutualConnect";

class DonationViewDonorsItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
        }
    }

    render() {
        const { type, data, index } = this.props;
        return (
            <li>                
                <div className="card" key={index}>
                    <div className="card-header" id={'donationViewheading-' + index}>
                        <div className="userBlk" onClick={() => redirectToProfile(data.userCustomUrl)} style={{cursor: 'pointer'}}>
                            <figure className="users"><img src={data.userProfileImage ? getOptimizedImage(data, "userProfileImage") : data.userType === "INDIVIDUAL" ? "https://cdn.dscovr.com/images/users1.webp" : "https://cdn.dscovr.com/images/DefaultBusiness.webp"} alt="" /></figure>
                            <div>
                                <h5 className="name">{data.userId !== this.state.userData.userId ? <><span>{data.userName}</span>{data.connectDepth && <MutualConnect connectDepth={data.connectDepth}></MutualConnect>}</> : <><span>You</span></>}</h5>
                                <span className="persona">{data.persona}</span>
                                {data.addressDetail && <span className="mutual">{data.addressDetail?.country}</span>}
                            </div>
                        </div>
                        <div className="userDonateInfo">
                            <div className="donateDate">{getFormattedDate(data.date)}</div>                    
                        </div>
                        <div className="userDonateInfo columnDonate">
                            {type === "donors" || type === "donors_amount" || (type === "view" && data.totalAmount > 0) ?
                                <>
                                    <div className="donateStatus amount"><strong>{data.currencyType === "INR" ? <>&#8377;</> : <>&#36;</>}{convertCurrencySystem(type === "view" ? data.totalAmount : data.amount)}</strong></div>
                                    {data.bankDetail?.institutionName && <div className="donateBankName" title={data.bankDetail?.institutionName}>{data.bankDetail?.institutionName}</div>}
                                </> :
                                <div className="donateDate">{"-"}</div>
                            }
                        </div>
                        {type === "view" && data.seenList.length > 1 &&
                            <a role="button" data-toggle="collapse" href={'#donationViewcollapse-' + index} aria-expanded="false" className="btn viewBtnAcc"></a>
                        }
                    </div>
                    {type === "view" && data.seenList.length > 1 && 
                        <div id={'donationViewcollapse-' + index} className="collapse" data-parent="#donationViewAccordion" aria-labelledby={'donationViewheading-' + index}>
                            <div className="card-body">
                                {data.seenList && data.seenList.length > 0 && data.seenList.map((seenData) => {
                                    return (
                                        <li>
                                            <div className="userBlk"></div>
                                            <div className="userDonateInfo">
                                                <div className="donateDate">{getFormattedDate(seenData.time)}</div>                    
                                            </div>
                                            <div className="userDonateInfo columnDonate">
                                                {seenData.amount > 0 ?
                                                    <>
                                                        <div className="donateStatus amount"><strong>{seenData.currencyType === "INR" ? <>&#8377;</> : <>&#36;</>}{convertCurrencySystem(seenData.amount)}</strong></div>
                                                        <div className="donateBankName" title={seenData.bankDetail?.institutionName}>{seenData.bankDetail?.institutionName}</div>
                                                    </> :
                                                    <div className="donateDate">{"-"}</div>
                                                }
                                            </div>
                                        </li>
                                    )
                                })}
                            </div>
                        </div>
                    }
                </div>                    
            </li>
        );
    }
}

export default DonationViewDonorsItem;