import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import store from "./store";
import { syncHistoryWithStore } from "react-router-redux";
import { authService } from "./auth";
import httpService from "./AxiosInterceptors";

import Login from "./Login";
import IndividualSignUp from "./signup/IndividualSignUp";
import unsubscribe from "./unsubscribe";
import Signout from "./Signout";
import Otp from "./signup/otp";
import OtpNew from "./signup/otpNew";
import UserAddress from "./signup/UserAddress";
import OperatorInfo from "./signup/OperatorInfo";
import ForgotPasswordOtp from "./signup/forgotPasswordOtp";
import ChangePassword from "./signup/changePassword";
import ResetPassword from "./signup/ResetPassword";
import CreateBusiness from "./profile/component/business/CreateBusiness";
import FaQ from "./FaQ/FaQ";
import FaQFeedback from "./FaQ/FaQFeedback";
import FaQDetail from "./FaQ/FaQDetail";
import MediaInfo from "./static/mediaInfo";
import CommunityGuidelines from "./static/CommunityGuidelines";
import RefundPolicy from "./static/RefundPolicy";
import AuthModalComponent from "./LoginExtra/AuthModalComponent";
import ScrollToTop from "./commons/ScrollToTop";
import SecurityAndLogin from "./settings/SecurityAndLogin";
import NotificationSetting from "./settings/NotificationSetting";
import Feeds from "./Feeds/Feeds";
import CookieAcceptance from "./commons/CookieAcceptance";
import NotFound from "./404";
import FooterDiv from "./FooterDiv";
import Download from "./AppDownload/Download";
import ProfileDeactivationAdminRights from "./settings/ProfileDeactivationAdminRights";
import ProfileDeactivationUpdateAdminRights from "./settings/ProfileDeactivationUpdateAdminRights";
import Aasa from "./Aasa";
import DeactivatedProfile from "./profile/container/DeactivatedProfile";
import RestrictedProfile from "./profile/container/RestrictedProfile";
import PostAsDetails from "./static/PostAsDetails";
import FaQBypassDetails from "./FaQ/FaQBypassDetails";
import { retry } from "./commons/PromiseRetry";
import ErrorBoundary from "./commons/ErrorBoundaries";
import CauseDetail from "./profile/component/commons/CauseDetail";
import Feedback from "./feedback/Feedback";
import Loader from "react-loader-spinner";
import DeletedCircle from "./circle/pages/DeletedCircle";
import DeletedUnAuthCircle from "./circle/pages/DeletedUnAuthCircle";
import DeactivatedUnAuthProfile from "./profile/container/profile/DeactivatedUnAuthProfile";
import FeedsHidden from "./HideContent/Tabs/Feeds";
import ProjectHidden from "./HideContent/Tabs/Project";
import ForumAndPoll from "./HideContent/Tabs/ForumAndPoll";
import HideContentAbout from "./HideContent/HideContentAbout";
import LeftPanelUnauth from "./commons/LeftPanelUnauth";
import TagManager from "react-gtm-module";
import MobileLogin from "./LoginExtra/MobileLogin";
import NomineeVotersList from "./world-people-of-nature-day/WpondUpcoming/NomineeVotersList";
import UserDashboard from "./UserDashboard/UserDashboard";
import DonationIndividual from "./UserDashboard/DonationIndividual";
import Stats from "./circle/pages/stats";
import ManageStudioHidden from "./project/component/Studio/ManageStudioHidden";
import Bookmark from "./UserDashboard/Bookmark";
import AllHashTagsStudios from "./explore/HashTags/AllHashTagsStudios";
import AllIndividualHashTagsStudios from "./explore/HashTags/AllIndividualHashTagsStudios";
import BlockedUserList from "./network/BlockedUserList";
import AllHashTagsDocuments from "./explore/HashTags/AllHashTagsDocuments";
import AllIndividualHashTagsDocuments from "./explore/HashTags/AllIndividualHashTagsDocuments";
import RedirectionComponent from "./RedirectionComponent";
import clevertap from 'clevertap-web-sdk';

if(process.env.REACT_APP_environment === "prod") {
  const tagManagerArgs = {
    gtmId: "GTM-P5Z4BWZ",
  };
  TagManager.initialize(tagManagerArgs);
  clevertap.init('8R5-WK4-996Z');
} 

if(process.env.REACT_APP_environment != "prod") {
  clevertap.init('7K9-5K9-696Z');
} 


const EditProjectNative = lazy(() => retry(() => import("./project/ProjectForms/EditProjectNative")))
const Home = lazy(() => retry(() => import("./Home")));
const About = lazy(() => retry(() => import("./About/About")));
const Landing = lazy(() => retry(() => import("./landing/Landing")));
const TestimonialDetails = lazy(() => retry(() => import("./landing/TestimonialDetails")));
const Header = lazy(() => retry(() => import("./Header")));
const How = lazy(() => retry(() => import("./HowItWorks/How")));
const Forum = lazy(() => retry(() => import("./forum/Forum")));
const ForumPage = lazy(() => retry(() => import("./forum/ForumPage")));
const PollPage = lazy(() => retry(() => import("./forum/PollPage")));
const Poll = lazy(() => retry(() => import("./forum/Poll")));
const ForumAndPollDraft = lazy(() =>
  retry(() => import("./drafts/container/ForumAndPollDraft"))
);
const ManageInvitation = lazy(() =>
  retry(() => import("./invitation/ManageInvitation"))
);
const UserAgreement = lazy(() => retry(() => import("./static/UserAgreement")));
const CookiePolicy = lazy(() => retry(() => import("./static/CookiePolicy")));
const PrivacyPolicy = lazy(() => retry(() => import("./static/PrivacyPolicy")));
const TermsConditions = lazy(() => retry(() => import("./static/TermsConditions")));
const StudioTermsConditions = lazy(() => retry(() => import("./static/StudioTermsConditions")));
const CircleProfileHomeEditor = lazy(() =>
  retry(() => import("./circle/editcircle/CircleProfileHomeEditor"))
);
const ArticleDrafts = lazy(() =>
  retry(() => import("./drafts/container/drafts-article"))
);
const BusinessProfilePic = lazy(() =>
  retry(() => import("./profile/component/business/BusinessProfilePic"))
);
const PersonalizeProfile = lazy(() =>
  retry(() => import("./signup/PersonalizeProfile"))
);
const CompanyProfile = lazy(() =>
  retry(() => import("./signup/CompanyProfile"))
);
const CompanyOtherActivity = lazy(() =>
  retry(() =>
    import("./profile/component/CompanyProfileOther/CompanyOtherActivity")
  )
);
const OtherCompanyOpportunities = lazy(() =>
  retry(() =>
    import("./profile/component/CompanyProfileOther/OtherCompanyOpportunities")
  )
);
const CompanyOpportunities = lazy(() =>
  retry(() => import("./profile/component/CompanyProfile/CompanyOpportunities"))
);
const Draft = lazy(() => retry(() => import("./drafts/container/Draft")));
const AboutCircle = lazy(() => retry(() => import("./circle/pages/about")));
const Circleactivity = lazy(() =>
  retry(() => import("./circle/pages/activity"))
);
const Circleprojects = lazy(() =>
  retry(() => import("./circle/pages/projects"))
);
const CircleStudioPage = lazy(() => 
  retry(() => import("./circle/pages/CircleStudioPage"))
);
const CircleArticlePage = lazy(() => 
  retry(() => import("./circle/pages/CircleArticlePage"))
);
const Circlemembers = lazy(() => retry(() => import("./circle/pages/members")));
const AllMember = lazy(() => retry(() => import("./circle/pages/AllMember")));
const CircleAwardsAndAccolades = lazy(() =>
  retry(() => import("./circle/pages/CircleAwardsAndAccolades"))
);
const NotificationShowMore = lazy(() =>
  retry(() => import("./commons/NotificationShowMore"))
);
const PostDetail = lazy(() => retry(() => import("./post/PostDetail")));
const EmailVerification = lazy(() =>
  retry(() => import("./EmailVerification"))
);
const TransferConfirmation = lazy(() =>
  retry(() => import("./TransferConfirmation"))
);
const Project = lazy(() =>
  retry(() => import("./project/container/Project/Project"))
);
const Activity = lazy(() =>
  retry(() => import("./profile/component/PersonalProfile/Activity"))
);
const AllActivity = lazy(() =>
  retry(() => import("./profile/component/PersonalProfile/ViewAllActivity"))
);
const ProfileFollowerList = lazy(() =>
  retry(() => import("./profile/component/PersonalProfile/ProfileFollowerList"))
);
const ProfileConnectionsList = lazy(() =>
  retry(() =>
    import("./profile/component/PersonalProfile/ProfileConnectionsList")
  )
);
const ProfileMutualConnectList = lazy(() =>
  retry(() =>
    import("./profile/component/PersonalProfile/ProfileMutualConnectList")
  )
);
const ProfileHashTagPage = lazy(() =>
  retry(() => import("./profile/component/PersonalProfile/ProfileHashTagPage"))
);
const AwardsAndAccolades = lazy(() =>
  retry(() => import("./profile/component/PersonalProfile/AwardsAndAccolades"))
);
const CompanyActivity = lazy(() =>
  retry(() => import("./profile/component/CompanyProfile/CompanyActivity"))
);
const CompanyFollowers = lazy(() =>
  retry(() => import("./profile/component/CompanyProfile/CompanyFollowers"))
);
const CompanyOtherEmployee = lazy(() =>
  retry(() => import("./profile/component/CompanyProfile/CompanyOtherEmployee"))
);
const CompanyEmployee = lazy(() =>
  retry(() => import("./profile/component/CompanyProfile/CompanyEmployee"))
);
const CompanyOtherFollowers = lazy(() =>
  retry(() =>
    import("./profile/component/CompanyProfileOther/CompanyOtherFollowers")
  )
);
const CompanyAwardsAndAccolades = lazy(() =>
  retry(() =>
    import("./profile/component/CompanyProfile/CompanyAwardsAndAccolades")
  )
);
const CompanyOtherAwardsAndAccolades = lazy(() =>
  retry(() =>
    import(
      "./profile/component/CompanyProfileOther/CompanyOtherAwardsAndAccolades"
    )
  )
);
const ProfileHome = lazy(() =>
  retry(() => import("./profile/container/profile/ProfileHome"))
);
const ProfileHomeEditor = lazy(() =>
  retry(() => import("./profile/container/ProfileHomeEditor"))
);
const ShowMoreContainer = lazy(() =>
  retry(() => import("./view/ShowMoreContainer"))
);
const ShowAllPrivateProject = lazy(() =>
  retry(() => import("./project/component/privateProject/ShowAllPrivateProject"))
);
const ProjectDetailsContainer = lazy(() =>
  retry(() => import("./project/container/Project/ProjectDetailsContainer"))
);
const Message = lazy(() => retry(() => import("./Message")));
const MessageToUser = lazy(() => retry(() => import("./MessageToUser")));
const ExploreTrending = lazy(() =>
  retry(() => import("./explore/ExploreTrending"))
);
const BusinessAccountOperatorInfo = lazy(() =>
  retry(() =>
    import("./profile/component/business/BusinessAccountOperatorInfo")
  )
);
const Settings = lazy(() => retry(() => import("./settings/Settings")));
const BusinessPageSettings = lazy(() =>
  retry(() => import("./settings/BusinessPageSettings"))
);
const CircleSettings = lazy(() =>
  retry(() => import("./settings/CircleSettings"))
);
const ExploreCurated = lazy(() =>
  retry(() => import("./explore/ExploreCurated"))
);
const AllExploreCuratedDetail = lazy(() =>
  retry(() => import("./explore/AllExploreCuratedDetail"))
);
const AllExploreBLogTrending = lazy(() =>
  retry(() => import("./explore/AllExploreTrendingBlogs"))
);
const AllExplorePhotoTrending = lazy(() =>
  retry(() => import("./explore/AllExploreTrendingPhotos"))
);
const AllExploreVideoTrending = lazy(() =>
  retry(() => import("./explore/AllExploreTrendingVideos"))
);
const AllExploreCircleTrending = lazy(() =>
  retry(() => import("./explore/AllExploreTrendingCircle"))
);
const AllExploreCauseTrending = lazy(() =>
  retry(() => import("./explore/AllExploreTrendingCause"))
);
const AllExploreDocumentTrending = lazy(() =>
  retry(() => import("./explore/AllExploreTrendingDocuments"))
);
const ExploreLatest = lazy(() =>
  retry(() => import("./explore/ExploreLatest"))
);
const ExploreDetail = lazy(() =>
  retry(() => import("./explore/ExploreDetail"))
);
const ExploreDailyEdition = lazy(() =>
  retry(() => import("./explore/ExploreDailyEdition"))
);
const AllExploreJustForYou = lazy(() =>
  retry(() => import("./explore/AllExploreJustForYou"))
);
const ExploreSummary = lazy(() =>
  retry(() => import("./explore/ExploreSummary"))
);
const AllExplorePopularStory = lazy(() =>
  retry(() => import("./explore/AllExplorePopularStory"))
);
const ExploreHashTag = lazy(() =>
  retry(() => import("./explore/ExploreHashTag"))
);
const PollDetail = lazy(() => retry(() => import("./forum/PollDetail")));
const ForumDetail = lazy(() => retry(() => import("./forum/ForumDetail")));
const Network = lazy(() => retry(() => import("./network/Network")));
const PopularUsers = lazy(() => retry(() => import("./network/PopularUsers")));
const YouMayKnowUsers = lazy(() =>
  retry(() => import("./network/YouMayKnowUsers"))
);
const Followers = lazy(() => retry(() => import("./network/Followers")));
const Followees = lazy(() => retry(() => import("./network/Followees")));
const BusinessPages = lazy(() =>
  retry(() => import("./network/BusinessPages"))
);
const Groups = lazy(() => retry(() => import("./network/Groups")));
const connected = lazy(() =>
  retry(() => import("./network/component/connected"))
);
const CirlePage = lazy(() =>
  retry(() => import("./network/component/circles"))
);
const organizationPage = lazy(() =>
  retry(() => import("./network/component/organization"))
);
const following = lazy(() =>
  retry(() => import("./network/component/following"))
);
const pages = lazy(() => retry(() => import("./network/component/pages")));
const groups = lazy(() => retry(() => import("./network/component/groups")));
const FollowingPageList = lazy(() =>
  retry(() => import("./network/FollowingPageList"))
);
const AllCircleSuggest = lazy(() =>
  retry(() => import("./network/AllCircleSuggest"))
);
const FollowingCircleList = lazy(() =>
  retry(() => import("./network/FollowingCircleList"))
);
const ConnectRequests = lazy(() =>
  retry(() => import("./network/ConnectRequests"))
);
const Invitations = lazy(() => retry(() => import("./network/Invitations")));
const AllCauses = lazy(() => retry(() => import("./cause/AllCauses")));
const CeoDesk = lazy(() =>
  retry(() => import("./LandingBlog/component/CeoDesk"))
);
const TheStoryBehindOurPassion = lazy(() =>
  retry(() => import("./LandingBlog/component/TheStoryBehindOurPassion"))
);
const ThisIsHowItAllBegan = lazy(() =>
  retry(() => import("./LandingBlog/component/ThisIsHowItAllBegan"))
);
const BuildingAnImpactfulWorld = lazy(() =>
  retry(() => import("./LandingBlog/component/BuildingAnImpactfulWorld"))
);
const Circle = lazy(() => retry(() => import("./circle/circle")));
const ProfileByPass = lazy(() => retry(() => import("./ProfileByPass")));
const FaQByPass = lazy(() => retry(() => import("./FaQ/FaQByPass")));
const UnAuthAboutCircle = lazy(() =>
  retry(() => import("./circle/UnAuth/unAuthAboutCircle"))
);
const UnAuthCircleList = lazy(() =>
  retry(() => import("./circle/UnAuth/unAuthCircleList"))
);
const LeftPanel = lazy(() => retry(() => import("./LeftPanel")));
const StaticHeader = lazy(() => retry(() => import("./StaticHeader")));
const ProjectFeedsPhotoDetails = lazy(() =>
  retry(() => import("./project/component/feeds/ProjectFeedsPhotoDetails"))
);
const HideContent = lazy(() =>
  retry(() => import("./HideContent/HideContent"))
);
const AllNewApplication = lazy(() =>
  retry(() => import("./project/container/Project/AllNewApplication"))
);
const PeopoleOfNature = lazy(() =>
  retry(() => import("./static/PepoleOfNature"))
);
const WpondStatic2021 = lazy(() =>
  retry(() => import("./world-people-of-nature-day/WpondStatic2021"))
);
const WorldPeopleOfNatureDay = lazy(() =>
  retry(() => import("./world-people-of-nature-day/WorldPeopleOfNatureDay"))
);
const WPONDComplete = lazy(() =>
  retry(() => import("./world-people-of-nature-day/PeopleOfNatureCompleted"))
);
const WPONDUpcoming = lazy(() =>
  retry(() => import("./world-people-of-nature-day/PeopleOfNatureUpcoming"))
);
const WpondFaQPage = lazy(() =>
  retry(() => import("./world-people-of-nature-day/WpondFaQPage"))
);
const WpondUpcomingShareablePage = lazy(() =>
  retry(() => import("./world-people-of-nature-day/WpondUpcomingShareablePage"))
);
const WpondAwardShareablePage = lazy(() =>
  retry(() => import("./world-people-of-nature-day/WpondAwardShareablePage"))
);
const AwardNomineeList = lazy(() =>
  retry(() => import("./world-people-of-nature-day/WpondUpcoming/AwardNomineeList"))
);
const WpondItemList = lazy(() =>
  retry(() => import("./world-people-of-nature-day/WpondItemList"))
);
const StoryBehindAward = lazy(() =>
  retry(() => import("./static/StoryBehindAward"))
);
const HowWeImpact = lazy(() =>
  retry(() => import("./how-we-impact/HowWeImpact"))
);
const HowWeImpactCategory = lazy(() =>
  retry(() => import("./how-we-impact/HowWeImpactCategory"))
);
const HowWeImpactCategoryArticle = lazy(() =>
  retry(() => import("./how-we-impact/HowWeImpactCategoryArticle"))
);
const HowWeImpactFindHow = lazy(() =>
  retry(() => import("./how-we-impact/HowWeImpactFindHow"))
);
const CircleViewAll = lazy(() =>
  retry(() => import("./circle/post/ViewAllActivity"))
);
const IndividualHashTags = lazy(() =>
  retry(() => import("./explore/HashTags/IndividualHashTags"))
);
const HashTagsMain = lazy(() =>
  retry(() => import("./explore/HashTags/HashTagsMain"))
);
const AllHashTagsPhotos = lazy(() =>
  retry(() => import("./explore/HashTags/AllHashTagsPhotos"))
);
const AllHashTagsVideoes = lazy(() =>
  retry(() => import("./explore/HashTags/AllHashTagsVideos"))
);
const AllHashTagsBlogs = lazy(() =>
  retry(() => import("./explore/HashTags/AllHashTagsBlogs"))
);
const AllHashTagsForums = lazy(() =>
  retry(() => import("./explore/HashTags/AllHashTagsForums"))
);
const AllHashTagsPolls = lazy(() =>
  retry(() => import("./explore/HashTags/AllHashTagsPolls"))
);
const AllHashTagsProjects = lazy(() =>
  retry(() => import("./explore/HashTags/AllHashTagsProjects"))
);
const AllHashTagsTopContributors = lazy(() =>
  retry(() => import("./explore/HashTags/AllHashTagsTopContributors"))
);
const AllHashTagsRecentActivity = lazy(() =>
  retry(() => import("./explore/HashTags/AllHashTagsRecentActivity"))
);
const AllHashTagsOthers = lazy(() =>
  retry(() => import("./explore/HashTags/AllHashTagsOthers"))
);
const AllIndividualHashTagsBlogs = lazy(() =>
  retry(() => import("./explore/HashTags/AllIndividualHashTagsBlogs"))
);
const AllIndividualHashTagsPhotos = lazy(() =>
  retry(() => import("./explore/HashTags/AllIndividualHashTagsPhotos"))
);
const AllIndividualHashTagsVideos = lazy(() =>
  retry(() => import("./explore/HashTags/AllIndividualHashTagsVideos"))
);
const AllIndividualHashTagsForums = lazy(() =>
  retry(() => import("./explore/HashTags/AllIndividualHashTagsForums"))
);
const AllIndividualHashTagsPolls = lazy(() =>
  retry(() => import("./explore/HashTags/AllIndividualHashTagsPolls"))
);
const AllIndividualHashTagsProjects = lazy(() =>
  retry(() => import("./explore/HashTags/AllIndividualHashTagsProjects"))
);
const AllIndividualHashTagsTopContributors = lazy(() =>
  retry(() => import("./explore/HashTags/AllIndividualHashTagsTopContributors"))
);
const AllIndividualHashTagsRecentActivity = lazy(() =>
  retry(() => import("./explore/HashTags/AllIndividualHashTagsRecentActivity"))
);
const AllIndividualHashTagsOthers = lazy(() =>
  retry(() => import("./explore/HashTags/AllIndividualHashTagsOthers"))
);
const CreateProjectNative = lazy(() =>
  retry(() => import("./project/ProjectForms/CreateProjectNative"))
);
const Subscription = lazy(() =>
  retry(() => import("./subscription/subscription"))
);
const ProfileSimilarUserList = lazy(() =>
  retry(() => import("./commons/ProfileSimilarUserList"))
);
const DonationViewDonorsUserList = lazy(() =>
  retry(() => import("./Donation/DonationViewDonorsUserList"))
);
const ManagePermissions = lazy(() =>
  retry(() => import("./settings/ManagePermissions"))
);
const ManagePermissionsAdmin = lazy(() =>
  retry(() => import("./settings/ManageCirclePermissionDetails"))
);
const ManagePermissionsMembers = lazy(() =>
  retry(() => import("./settings/ManageMemberPermissionDetails"))
);
const ManagePermissionsParticipants = lazy(() =>
  retry(() => import("./settings/ManageParticipantsPermissionDetails"))
);
const ManagePermissionsPrivateProject = lazy(() =>
  retry(() => import("./settings/ManagePrivateProjectPermissionDetails"))
);

const Transactions = lazy(() =>
  retry(() => import("./settings/Transactions"))
);

const ProjectStudioContainer = lazy(() =>
  retry(() => import("./project/container/Project/ProjectStudioContainer"))
);
const StudioContentForm = lazy(() =>
  retry(() => import("./project/component/Studio/StudioContentForm"))
);
const StudioDetailsPage = lazy(() =>
  retry(() => import("./project/component/Studio/StudioDetailsPage"))
);
const StudioContainerGlobal = lazy(() =>
  retry(() => import("./project/component/Studio/StudioContainerGlobal"))
);
const ManageStudio = lazy(() =>
  retry(() => import("./project/component/Studio/ManageStudio"))
);
const ManageStudioSubscribe = lazy(() =>
  retry(() => import("./project/component/Studio/ManageStudioSubscribe"))
);
const ManageStudioHelpDesk = lazy(() =>
  retry(() => import("./project/component/Studio/ManageStudioHelpDesk"))
);

const HelpDeskAllStudioListData = lazy(() =>
  retry(() => import("./project/component/Studio/HelpDeskAllStudioListData"))
);

const ManageStudioInterested = lazy(() =>
  retry(() => import('./project/component/Studio/ManageStudioInterested'))
);
const Admin = lazy(() =>
  retry(() => import("./AdminPanel/Admin"))
);

const Analytics = lazy(() =>
  retry(() => import("./Analytics/Analytics"))
);

const BlogPopupMobile = lazy(() =>
  retry(() => import("./BlogPopUpMobile"))
);

const CreateProjectForm = lazy(() =>
  retry(() => import("./project/ProjectForms/CreateProjectForm"))
);

const EditProjectForm = lazy(() =>
  retry(() => import("./project/ProjectForms/EditProjectForm"))
);

const MyForumPollSection = lazy(() => 
  retry(() => import("./UserDashboard/MyForumPollSection"))
);


const ProfileStudioIndividualPage = lazy(() => 
  retry(() => import("./profile/component/PersonalProfile/ProfileStudioIndividualPage"))
);

const ProfileArticleIndividualPage = lazy(() => 
  retry(() => import("./profile/component/PersonalProfile/ProfileArticleIndividualPage"))
);

const ProfileProjectsIndividualPage = lazy(() => 
  retry(() => import("./profile/component/PersonalProfile/ProfileProjectsIndividualPage"))
);
const HeatMapAnalytics = lazy(() => retry(() => import("./Analytics/HeatMapAnalytics")));
const CircleHashTagPage = lazy(() => retry(() => import("./circle/CircleHashTagPage")));
const IndividualCircleHashTags = lazy(() => retry(() => import("./circle/IndividualCircleHashTags")));
const AllCommunityHashTagsTopContributors = lazy(() => retry(() => import("./explore/HashTags/AllCommunityHashTagsTopContributors")));
const AllCommunityHashTagsPhotos = lazy(() => retry(() => import("./explore/HashTags/AllCommunityHashTagsPhotos")));
const AllCommunityHashTagsBlogs = lazy(() => retry(() => import("./explore/HashTags/AllCommunityHashTagsBlogs")));
const AllCommunityHashTagsDocuments = lazy(() => retry(() => import("./explore/HashTags/AllCommunityHashTagsDocuments")));
const AllCommunityHashTagsStudios = lazy(() => retry(() => import("./explore/HashTags/AllCommunityHashTagsStudios")));
const AllCommunityHashTagsOthers = lazy(() => retry(() => import("./explore/HashTags/AllCommunityHashTagsOthers")));
const AllCommunityHashTagsProjects = lazy(() => retry(() => import("./explore/HashTags/AllCommunityHashTagsProjects")));
const AllCommunityHashTagsVideos = lazy(() => retry(() => import("./explore/HashTags/AllCommunityHashTagsVideos")));
const OurSoliutionList = lazy(() => retry(() => import("./OurSolution/OurSoliutionList")));

const BadgeDashboard = lazy(() => retry(() => import("./Badge/BadgeDashBoard")));

const browserHistory = createBrowserHistory();
const history = syncHistoryWithStore(browserHistory, store);

httpService.setupInterceptors();

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginSuccess: authService.isAuthenticated(),
      userData: authService.getUserDetailsFromLocalStorage(),
    };
  }

  redirect = (data) => {
    /*if (data.mobileVerificationRequired) {
      history.push({
        pathname: "/otp",
        state: {
          signUpResponse: data,
        },
      });
    }*/
    if (data.emailVerificationRequired) {
      this.props.history.push({
        pathname: '/signup-verify',
        state: {
          signUpResponse: data
        }
      })
    } else if (data.addressRequired) {
      history.push({
        pathname: "/address",
        state: {
          signUpResponse: data,
        },
      });
    } else if (data.personalProfileRequired) {
      history.push({
        pathname: "/personal",
      });
    } else if (data.operatorInfoRequired) {
      history.push({
        pathname: "/operatorinfo",
      });
    } else if (data.companyProfileRequired) {
      history.push({
        pathname: "/companyprofile",
      });
    }
  };

  loginRequired = (slug) => {
    this.setState({ loginRequired: true, pathname: slug });
    window.$("#loginModal").modal("show");
  };
  handleLoginClose = () => {
    this.setState({ loginRequired: false });
    window.$("#loginModal").modal("hide");
  };

  signUpRequired = () => {
    this.setState({ signUpRequired: true });
    window.$("#signupModal").modal("show");
  };

  handleSignUpClose = () => {
    this.setState({ signUpRequired: false });
    window.$("#signupModal").modal("hide");
  };

  render() {
    return (
      <div style={{ height: "100%" }}>
        <Provider store={store}>
          <Router history={history}>
            <Suspense
              fallback={
                <span style={{ textAlign: "center", display: "block" }}>
                  <div
                    style={{
                      display: "inline-block",
                      marginTop: "300px",
                    }}
                  >
                    <Loader
                      type="Grid"
                      color="#dcde63"
                      height={60}
                      width={60}
                      timeout={8000}
                    />
                  </div>
                </span>
              }
            >
              <ScrollToTop>
                <ErrorBoundary>
                  <Switch>
                    <Route exact path="/" component={(props) => <Landing {...props} />} />
                    {/* <Route exact path="/"
                                            component={(window.ReactNativeWebView && !this.state.loginSuccess) ? Login : Landing} /> */}
                    <Route
                      path="/home"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/home"
                          />
                        ) : (
                          <Home {...props}/>
                        )
                      }
                    />
                    <Route
                      path="/landing"
                      component={window.ReactNativeWebView ? Login : Landing}
                    />
                    <Route exact path="/show-all/testimonial" component={TestimonialDetails} />
                    <Route path="/login" component={Login} />
                    <Route path="/login-mob" component={MobileLogin} />
                    {/* <Route
                      path="/apple-app-site-association"
                      component={Aasa}
                    /> */}
                    <Route path="/signup" component={IndividualSignUp} />
                    {/*<Route path="/signup-company" component={CompanySignUp}/>*/}
                    <Route path="/about-us" component={About} />
                    <Route path="/operatorinfo" component={OperatorInfo} />
                    <Route path="/otp" component={Otp} />
                    <Route path="/signup-verify" component={OtpNew} />
                    <Route path="/address" component={UserAddress} />
                    <Route path="/personal" component={PersonalizeProfile} />
                    <Route path="/companyprofile" component={CompanyProfile} />
                    <Route
                      path="/create-Organization"
                      component={CreateBusiness}
                    />
                    <Route
                      path="/business-operator-info"
                      component={BusinessAccountOperatorInfo}
                    />
                    <Route
                      path="/business-profile-info"
                      component={BusinessProfilePic}
                    />
                    <Route path="/reset-password" component={ResetPassword} />
                    <Route path="/change-password" component={ChangePassword} />
                    <Route
                      path="/forgot-password-otp"
                      component={ForgotPasswordOtp}
                    />
                    {!this.state.loginSuccess ? (
                      <Route
                        path="/profile/:customUrl"
                        component={(props) => (
                          <ProfileByPass
                            key={props.match.params.customUrl}
                            {...props}
                          />
                        )}
                      />
                    ) : (
                      <Route
                        path="/profile/:customUrl"
                        component={(props) => (
                          <ProfileHome
                            key={props.match.params.customUrl}
                            {...props}
                          />
                        )}
                      />
                    )}
                    <Route
                      path="/cause/:id"
                      component={(props) => (
                        <CauseDetail key={props.match.params.id} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/profile-hashtag/:slug/:customurl"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent redirect={this.redirect} />
                        ) : (
                          <IndividualHashTags {...props} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/profile-hashtag/blogs/:slug/:customurl"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent redirect={this.redirect} />
                        ) : (
                          <AllIndividualHashTagsBlogs {...props} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/profile-hashtag/photos/:slug/:customurl"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent redirect={this.redirect} />
                        ) : (
                          <AllIndividualHashTagsPhotos {...props} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/profile-hashtag/videos/:slug/:customurl"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent redirect={this.redirect} />
                        ) : (
                          <AllIndividualHashTagsVideos {...props} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/profile-hashtag/projects/:slug/:customurl"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent redirect={this.redirect} />
                        ) : (
                          <AllIndividualHashTagsProjects {...props} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/profile-hashtag/others/:slug/:customurl"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent redirect={this.redirect} />
                        ) : (
                          <AllIndividualHashTagsOthers {...props} />
                        )
                      }
                    />
                     <Route
                      exact
                      path="/profile-hashtag/documents/:slug/:customurl"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent redirect={this.redirect} />
                        ) : (
                          <AllIndividualHashTagsDocuments {...props} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/profile-hashtag/forums/:slug/:customurl"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent redirect={this.redirect} />
                        ) : (
                          <AllIndividualHashTagsForums {...props} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/profile-hashtag/polls/:slug/:customurl"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent redirect={this.redirect} />
                        ) : (
                          <AllIndividualHashTagsPolls {...props} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/profile-hashtag/top-active-users/:slug/:customurl"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent redirect={this.redirect} />
                        ) : (
                          <AllIndividualHashTagsTopContributors {...props} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/profile-hashtag/recent-activity/:slug/:customurl"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent redirect={this.redirect} />
                        ) : (
                          <AllIndividualHashTagsRecentActivity {...props} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/profile-hashtag/studios/:slug/:customurl"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent redirect={this.redirect} />
                        ) : (
                          <AllIndividualHashTagsStudios {...props} />
                        )
                      }
                    />
                    <Route
                      path="/followers/:customUrl"
                      component={ProfileFollowerList}
                    />
                    <Route
                      path="/connections/:customUrl"
                      component={ProfileConnectionsList}
                    />
                    <Route
                      path="/mutual-connect/:customUrl"
                      component={ProfileMutualConnectList}
                    />
                    <Route
                      path="/profile-hashtag/:customUrl"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent redirect={this.redirect} />
                        ) : (
                          <ProfileHashTagPage {...props} />
                        )
                      }
                    />

                    

                    <Route
                      path="/profile-award/:customUrl"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent redirect={this.redirect} />
                        ) : (
                          <AwardsAndAccolades {...props} />
                        )
                      }
                    />
                    <Route
                      path="/profile-studio/:customUrl"
                      component={(props) =>
                        <ProfileStudioIndividualPage {...props} />
                      }
                      exact
                    />
                    <Route path="/profile-article/:customUrl" component={(props) =>
                        <ProfileArticleIndividualPage {...props} />
                      }
                      exact
                    />
                    <Route path="/profile-project/:customUrl" component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                          />
                        ) : (
                          <ProfileProjectsIndividualPage {...props} />
                        )
                      }
                      exact
                    />
                    <Route path="/causes" component={AllCauses} />
                    {!this.state.loginSuccess ? (
                      <Route
                        path="/profile-activity/:customUrl"
                        component={() => (
                          <AuthModalComponent redirect={this.redirect} />
                        )}
                      />
                    ) : (
                      <Route
                        path="/profile-activity/:customUrl"
                        component={Activity}
                      />
                    )}
                    {!this.state.loginSuccess ? (
                      <Route
                        path="/all-activity/:customUrl"
                        component={() => (
                          <AuthModalComponent redirect={this.redirect} />
                        )}
                      />
                    ) : (
                      <Route
                        path="/all-activity/:customUrl"
                        component={AllActivity}
                      />
                    )}
                    {!this.state.loginSuccess ? (
                      <Route
                        path="/organization-activity/:customUrl"
                        component={() => (
                          <AuthModalComponent redirect={this.redirect} />
                        )}
                      />
                    ) : (
                      <Route
                        path="/organization-activity/:customUrl"
                        component={CompanyActivity}
                      />
                    )}
                    {!this.state.loginSuccess ? (
                      <Route
                        path="/organization-other-activity/:customUrl"
                        component={() => (
                          <AuthModalComponent redirect={this.redirect} />
                        )}
                      />
                    ) : (
                      <Route
                        path="/organization-other-activity/:customUrl"
                        component={CompanyOtherActivity}
                      />
                    )}
                    {!this.state.loginSuccess ? (
                      <Route
                        path="/organization-employee/:customUrl"
                        component={() => (
                          <AuthModalComponent redirect={this.redirect} />
                        )}
                      />
                    ) : (
                      <Route
                        path="/organization-employee/:customUrl"
                        component={CompanyEmployee}
                      />
                    )}
                    {!this.state.loginSuccess ? (
                      <Route
                        path="/organization-other-employee/:customUrl"
                        component={() => (
                          <AuthModalComponent redirect={this.redirect} />
                        )}
                      />
                    ) : (
                      <Route
                        path="/organization-other-employee/:customUrl"
                        component={CompanyOtherEmployee}
                      />
                    )}
                    {!this.state.loginSuccess ? (
                      <Route
                        path="/organization-opportunities/:customUrl"
                        component={() => (
                          <AuthModalComponent redirect={this.redirect} />
                        )}
                      />
                    ) : (
                      <Route
                        path="/organization-opportunities/:customUrl"
                        component={CompanyOpportunities}
                      />
                    )}
                    {!this.state.loginSuccess ? (
                      <Route
                        path="/organization-other-opportunities/:customUrl"
                        component={() => (
                          <AuthModalComponent redirect={this.redirect} />
                        )}
                      />
                    ) : (
                      <Route
                        path="/organization-other-opportunities/:customUrl"
                        component={OtherCompanyOpportunities}
                      />
                    )}
                    {!this.state.loginSuccess ? (
                      <Route
                        path="/organization-followers/:customUrl"
                        component={() => (
                          <AuthModalComponent redirect={this.redirect} />
                        )}
                      />
                    ) : (
                      <Route
                        path="/organization-followers/:customUrl"
                        component={CompanyFollowers}
                      />
                    )}
                    {!this.state.loginSuccess ? (
                      <Route
                        path="/organization-other-followers/:customUrl"
                        component={() => (
                          <AuthModalComponent redirect={this.redirect} />
                        )}
                      />
                    ) : (
                      <Route
                        path="/organization-other-followers/:customUrl"
                        component={CompanyOtherFollowers}
                      />
                    )}
                    <Route
                      path="/organization-award/:customUrl"
                      component={CompanyAwardsAndAccolades}
                    />
                    <Route
                      path="/organization-other-award/:customUrl"
                      component={CompanyOtherAwardsAndAccolades}
                    />
                    {!this.state.loginSuccess ? (
                      <Route
                        path="/profile-edit/:customUrl"
                        component={() => (
                          <AuthModalComponent redirect={this.redirect} />
                        )}
                      />
                    ) : (
                      <Route
                        path="/profile-edit/:customUrl"
                        component={ProfileHomeEditor}
                      />
                    )}
                    <Route path="/message" component={Message} />
                    <Route path="/network" component={Network} />
                    <Route
                      path="/popular-users"
                      component={() =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/popular-users"
                          />
                        ) : (
                          <PopularUsers />
                        )
                      }
                    />
                    <Route
                      path="/connect-requests"
                      component={() =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/connect-requests"
                          />
                        ) : (
                          <ConnectRequests />
                        )
                      }
                    />
                    <Route
                      path="/notifications"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/notifications"
                          />
                        ) : (
                          <NotificationShowMore {...props} />
                        )
                      }
                    />
                    <Route
                      path="/invitations"
                      component={() =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/invitations"
                          />
                        ) : (
                          <Invitations />
                        )
                      }
                    />
                    <Route
                      path="/people-you-may-know"
                      component={() =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/people-you-may-know"
                          />
                        ) : (
                          <YouMayKnowUsers />
                        )
                      }
                    />
                    <Route
                      path="/blocked-users"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/blocked-users"
                          />
                        ) : (
                          <BlockedUserList {...props} />
                        )
                      }
                    />                    
                    <Route
                      path="/followers"
                      component={() =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/followers"
                          />
                        ) : (
                          <Followers />
                        )
                      }
                    />
                    <Route
                      path="/youfollow"
                      component={() =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/youfollow"
                          />
                        ) : (
                          <Followees />
                        )
                      }
                    />
                    <Route
                      path="/following-organization-pages"
                      component={() =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/following-organization-pages"
                          />
                        ) : (
                          <FollowingPageList />
                        )
                      }
                    />
                    <Route
                      path="/following-circles"
                      component={() =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/following-circles"
                          />
                        ) : (
                          <FollowingCircleList />
                        )
                      }
                    />
                    <Route
                      path="/suggestion-circles"
                      component={() =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/suggestion-circles"
                          />
                        ) : (
                          <AllCircleSuggest />
                        )
                      }
                    />
                    <Route
                      path="/groups"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/groups"
                          />
                        ) : (
                          <Groups {...props} />
                        )
                      }
                    />
                    <Route
                      path="/organization-pages"
                      component={() =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/organization-pages"
                          />
                        ) : (
                          <BusinessPages />
                        )
                      }
                    />
                    <Route
                      path="/profile-deactivate-admin-rights"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/profile-deactivate-admin-rights"
                          />
                        ) : (
                          <ProfileDeactivationAdminRights {...props} />
                        )
                      }
                    />
                    <Route
                      path="/profile-deactivate-update-admin-rights"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/profile-deactivate-update-admin-rights"
                          />
                        ) : (
                          <ProfileDeactivationUpdateAdminRights {...props} />
                        )
                      }
                    />
                    <Route exact path="/project" component={Project} />
                    <Route
                      path="/article-draft"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/article-draft"
                          />
                        ) : (
                          <ArticleDrafts {...props} />
                        )
                      }
                    />
                    <Route path="/forum" component={ForumPage} />
                    <Route path="/poll" component={PollPage} />
                    <Route
                      path="/forum-poll-draft"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/forum-poll-draft"
                          />
                        ) : (
                          <ForumAndPollDraft {...props} />
                        )
                      }
                    />

                    <Route
                      path="/dashboard-settings"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/dashboard-settings"
                          />
                        ) : (
                          <UserDashboard {...props} />
                        )
                      }
                    />

                    <Route
                      path="/heatmap-analytics"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/heatmap-analytics"
                          />
                        ) : (
                          <HeatMapAnalytics {...props} />
                        )
                      }
                    />

                    <Route
                      path="/my-forum"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/my-forum"
                          />
                        ) : (
                          <MyForumPollSection {...props} pageType={"MY_FORUM"} />
                        )
                      }
                    />
                    <Route
                      path="/my-poll"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/my-poll"
                          />
                        ) : (
                          <MyForumPollSection {...props} pageType={"MY_POLL"} />
                        )
                      }
                    />
                    <Route
                      path="/fund-raise"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/fund-raise"
                          />
                        ) : (
                          <DonationIndividual {...props} />
                        )
                      }
                    />
                    <Route
                      path="/manage-invitation"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/manage-invitation"
                          />
                        ) : (
                          <ManageInvitation {...props} />
                        )
                      }
                    />
                    <Route
                      path="/saved-posts"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/save-posts"
                          />
                        ) : (
                          <Bookmark {...props} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/explore-hashtag/:slug"
                      component={(props) => <HashTagsMain {...props} />}
                    />
                    <Route
                      exact
                      path="/explore-hashtag/photos/:slug"
                      component={(props) => <AllHashTagsPhotos {...props} />}
                    />
                    <Route
                      exact
                      path="/explore-hashtag/videos/:slug"
                      component={(props) => <AllHashTagsVideoes {...props} />}
                    />
                    <Route
                      exact
                      path="/explore-hashtag/studios/:slug"
                      component={(props) => <AllHashTagsStudios {...props} />}
                    />

                    <Route
                      exact
                      path="/explore-hashtag/blogs/:slug"
                      component={(props) => <AllHashTagsBlogs {...props} />}
                    />
                     <Route
                      exact
                      path="/explore-hashtag/documents/:slug"
                      component={(props) => <AllHashTagsDocuments {...props} />}
                    />
                    <Route
                      exact
                      path="/explore-hashtag/forums/:slug"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent redirect={this.redirect} />
                        ) : (
                          <AllHashTagsForums {...props} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/explore-hashtag/polls/:slug"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent redirect={this.redirect} />
                        ) : (
                          <AllHashTagsPolls {...props} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/explore-hashtag/projects/:slug"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent redirect={this.redirect} />
                        ) : (
                          <AllHashTagsProjects {...props} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/explore-hashtag/top-active-users/:slug"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent redirect={this.redirect} />
                        ) : (
                          <AllHashTagsTopContributors {...props} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/explore-hashtag/recent-activity/:slug"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent redirect={this.redirect} />
                        ) : (
                          <AllHashTagsRecentActivity {...props} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/explore-hashtag/others/:slug"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent redirect={this.redirect} />
                        ) : (
                          <AllHashTagsOthers {...props} />
                        )
                      }
                    />
                    <Route
                      path="/forumDetail/:slug"
                      component={(props) => (
                        <ForumDetail key={props.match.params.slug} {...props} />
                      )}
                    />
                    <Route
                      path="/pollDetail/:slug"
                      component={(props) => (
                        <PollDetail key={props.match.params.slug} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/post/:id"
                      component={(props) => (
                        <PostDetail key={props.match.params.id} {...props} />
                      )}
                    />
                    <Route
                      path="/verify/:customUrl"
                      component={(props) => (
                        <EmailVerification
                          key={props.match.params.customUrl}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      path="/confirm/admin-right/:entityId/:userId/:superAdminId/:slug"
                      component={(props) => (
                        <TransferConfirmation
                          key={props.match.params.customUrl}
                          {...props}
                          transferType={"PROJECT"}
                        />
                      )}
                    />
                    <Route
                      path="/verify-organization-transfer/:tokenId/:organizationId/:requestedUserId"
                      component={(props) => (
                        <TransferConfirmation
                          key={props.match.params.tokenId}
                          {...props}
                          transferType={"ORGANIZATION"}
                        />
                      )}
                    />
                    <Route path="/signout" component={Signout} />
                    {!this.state.loginSuccess ? (
                      <Route
                        path="/project-draft"
                        component={() => (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname={"/project-draft"}
                          />
                        )}
                      />
                    ) : (
                      <Route path="/project-draft" component={Draft} />
                    )}
                    {!this.state.loginSuccess ? (
                      <Route
                        path="/showMore/:type"
                        component={() => (
                          <AuthModalComponent redirect={this.redirect} />
                        )}
                      />
                    ) : (
                      <Route
                        path="/showMore/:type"
                        component={ShowMoreContainer}
                      />
                    )}
                    {!this.state.loginSuccess ? (
                      <Route
                        path="/show-all/private-project"
                        component={() => (
                          <AuthModalComponent redirect={this.redirect} />
                        )}
                      />
                    ) : (
                      <Route
                        path="/show-all/private-project"
                        component={ShowAllPrivateProject}
                      />
                    )}
                    <Route
                      path="/explore-daily-edition/just-for-you"
                      component={() => (this.state.loginSuccess ? <AllExploreJustForYou /> : <Redirect to="/explore"/> )} 
                    />
                    <Route
                      path="/explore-daily-edition/popular-story"
                      component={() => (this.state.loginSuccess ? <AllExplorePopularStory /> : <Redirect to="/explore"/> )}                    
                    />
                    <Route exact path="/explore">
                        <Redirect to={"/explore-daily-edition"} /> 
                    </Route>
                    <Route path="/explore" component={ExploreSummary} />
                    <Route
                      path="/explore-daily-edition"
                      component={ExploreDailyEdition}
                    />
                    <Route
                      path="/edit-project/:type/:slug"
                      component={EditProjectNative}
                      exact
                    />
                    <Route
                      path="/draft-project/:type/:slug"
                      component={EditProjectNative}
                      exact
                    />
                    <Route path="/create-project/:formType" component={CreateProjectNative} exact />
                    <Route
                      path="/project/:type/:projectId/grid"
                      component={(props) => (
                        <ProjectFeedsPhotoDetails
                          key={
                            props.match.params.type +
                            props.match.params.projectId
                          }
                          {...props}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/project/create-projects"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/project/create-projects"
                          />
                        ) : (
                          <CreateProjectForm {...props} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/project/edit-projects"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/project/edit-projects"
                          />
                        ) : (
                          <EditProjectForm {...props} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/project/:type/:projectId/new-applicants"
                      component={(props) => (
                        <AllNewApplication
                          key={
                            props.match.params.type +
                            props.match.params.projectId
                          }
                          {...props}
                        />
                      )}
                    />

                    <Route
                      exact
                      path="/project/studio/:slug"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                          />
                        ) : (
                          <ProjectStudioContainer {...props} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/:type/post-studio/:id"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                          />
                        ) : (
                          <StudioContentForm {...props} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/studio/:slug"
                      component={(props) => <StudioDetailsPage {...props} />}
                    />
                    <Route
                      exact
                      path="/studio"
                      component={(props) => <StudioContainerGlobal {...props} />}
                    />

                    <Route
                      exact
                      path="/:customUrl/studio"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                          />
                        ) : (
                          <ManageStudio key={props.match.params.customUrl} {...props} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/:customUrl/subscribed"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                          />
                        ) : (
                          <ManageStudioSubscribe key={props.match.params.customUrl} {...props} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/:customUrl/helpdesk"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                          />
                        ) : (
                          <ManageStudioHelpDesk key={props.match.params.customUrl} {...props} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/:customUrl/interested"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                          />
                        ) : (
                          <ManageStudioInterested key={props.match.params.customUrl} {...props} />
                        )
                      }
                    />
                   
                     <Route
                      exact
                      path="/:customUrl/hidden-studio"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                          />
                        ) : (
                          
                          <ManageStudioHidden key={props.match.params.customUrl} {...props}/>
                        )
                      }
                    />

                    <Route
                      exact
                      path="/:customUrl/helpdesk/studio-list/:userCustomUrl"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                          />
                        ) : (
                          <HelpDeskAllStudioListData key={props.match.params.customUrl} {...props} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/studio/:listType/list/:id/:entityId"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                          />
                        ) : (
                          <ProfileSimilarUserList {...props} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/post/:listType/list/:id"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                          />
                        ) : (
                          <ProfileSimilarUserList {...props} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/donation/:listType/list/:entitySlug"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                          />
                        ) : (
                          <DonationViewDonorsUserList {...props} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/project/:type/:projectId"
                      component={(props) => (
                        <ProjectDetailsContainer
                          key={
                            props.match.params.type +
                            props.match.params.projectId
                          }
                          {...props}
                        />
                      )}
                    />
                    <Route
                      path="/explore-latest"
                      component={() =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/explore-latest"
                          />
                        ) : (
                          <ExploreLatest />
                        )
                      }
                    />
                    <Route
                      path="/analytics"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/analytics"
                          />
                        ) : (
                          <Analytics {...props} />
                        )
                      }
                    />

                    {/* <Route exact path="/explore-trending/blogs">
                      <Redirect to="/explore-trending/articles" />
                    </Route> */}
                    
                    <Route
                      path="/explore-trending/blogs"
                      // path="/explore-trending/articles"
                      component={(props) => <AllExploreBLogTrending {...props} /> }
                    />
                    <Route
                      path="/explore-trending/photos"
                      component={(props) => <AllExplorePhotoTrending {...props} />}
                    />
                    <Route
                      path="/explore-trending/videos"
                      component={(props) => <AllExploreVideoTrending {...props} />}
                    />
                    <Route exact path="/explore-trending/circles">
                      <Redirect to="/explore-trending/community" />
                    </Route>
                    <Route
                      path="/explore-trending/community"
                      component={(props) => <AllExploreCircleTrending {...props} />}
                    />
                    <Route
                      path="/explore-trending/causes"
                      component={() =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/explore-trending/causes"
                          />
                        ) : (
                          <AllExploreCauseTrending />
                        )
                      }
                    />
                    <Route
                      path="/explore-trending/documents"
                      component={(props) => <AllExploreDocumentTrending {...props} /> }
                    />
                    <Route
                      path="/explore-trending"
                      component={() =>
                        <ExploreTrending />
                      }
                    />
                    <Route
                      exact
                      path="/explore-hashtag"
                      component={(props) =>
                        <ExploreHashTag {...props} />
                      }
                    />
                    <Route
                      path="/explore-detail/:type/:slug"
                      component={ExploreDetail}
                    />
                    <Route
                      path="/explore-curated/:id"
                      component={AllExploreCuratedDetail}
                    />
                    <Route path="/explore-curated" component={ExploreCurated} />
                    <Route path="/PrivacyPolicy" component={PrivacyPolicy} />
                    <Route path="/wenaturalists-pro" component={(props) =>
                      !this.state.loginSuccess ? (
                        <AuthModalComponent
                          redirect={this.redirect}
                          pathname="/wenaturalists-pro"
                        />
                      ) : (
                        <Subscription key={props.location && props.location.state && props.location.state.userId} {...props} />
                      )} />
                    <Route path="/:listType/list/:itemTitle/:customUrl" component={(props) =>
                      !this.state.loginSuccess ? (
                        <AuthModalComponent
                          redirect={this.redirect}
                          pathname="/"
                        />
                      ) : (
                        <ProfileSimilarUserList {...props} />
                      )} />
                    {/* <Route path="/:listType/list/:itemTitle/:customUrl" component={ProfileSimilarUserList} /> */}
                    <Route path="/admin" component={(props) =>
                      !this.state.loginSuccess ? (
                        <AuthModalComponent
                          redirect={this.redirect}
                          pathname="/admin"
                        />
                      ) : (
                        <Admin {...props} />
                      )} />
                    <Route path="/media-info" component={MediaInfo} />
                    <Route path="/post-help" component={PostAsDetails} />
                    <Route path="/share-help" component={PostAsDetails} />
                    <Route path="/UserAgreement" component={UserAgreement} />
                    <Route path="/CommunityGuidelines" component={CommunityGuidelines} />
                    <Route path="/refund-policy" component={RefundPolicy} />
                    <Route path="/CookiePolicy" component={CookiePolicy} />
                    <Route path="/studio-terms-conditions" component={StudioTermsConditions} />
                    {!this.state.loginSuccess ? (
                      <Route path="/FaQ" component={FaQByPass} />
                    ) : (
                      <Route path="/FaQ" component={FaQ} />
                    )}
                    <Route
                      path="/faq-details/:id"
                      component={(props) => (
                        <FaQBypassDetails
                          key={props.match.params.id}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/FaQFeedback"
                      component={() =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/FaQFeedback"
                          />
                        ) : (
                          <FaQFeedback />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/Feedback"
                      component={() =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/Feedback"
                          />
                        ) : (
                          <Feedback />
                        )
                      }
                    />
                    {!this.state.loginSuccess ? (
                      <Route
                        path="/FaQDetail/:sec/:id"
                        component={() => (
                          <AuthModalComponent redirect={this.redirect} />
                        )}
                      />
                    ) : (
                      <Route path="/FaQDetail/:sec/:id" component={FaQDetail} />
                    )}
                    <Route
                      path="/TermsConditions"
                      component={TermsConditions}
                    />
                    {/* <Route
                      exact
                      path="/unavailable"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <DeactivatedUnAuthProfile {...props} />
                        ) : (
                          <DeactivatedProfile {...props} />
                        )
                      }
                    /> */}
                    <Route
                      exact
                      path="/restricted-view"
                      component={(props) => <RestrictedProfile {...props} />}
                    />
                    <Route exact path="/circle/unavailable">
                      <Redirect to="/community/unavailable" />
                    </Route>
                    <Route
                      exact
                      path="/community/unavailable"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <DeletedUnAuthCircle />
                        ) : (
                          <DeletedCircle {...props} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/privacy-settings"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/privacy-settings"
                          />
                        ) : (
                          <Settings {...props} />
                        )
                      }
                    />

                    <Route
                      exact
                      path="/organization-privacy-settings"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/organization-privacy-settings"
                          />
                        ) : (
                          <BusinessPageSettings {...props} />
                        )
                      }
                    />
                    <Route exact path="/circle-privacy-settings">
                      <Redirect to="/community-privacy-settings" />
                    </Route>
                    <Route
                      exact
                      path="/community-privacy-settings"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/community-privacy-settings"
                          />
                        ) : (
                          <CircleSettings {...props} />
                        )
                      }
                    />

                    <Route
                      exact
                      path="/security-and-login"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/security-and-login"
                          />
                        ) : (
                          <SecurityAndLogin {...props} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/notification-setting"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/notification-setting"
                          />
                        ) : (
                          <NotificationSetting {...props} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/manage-permissions"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/manage-permissions"
                          />
                        ) : (
                          <ManagePermissions {...props} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/manage-permissions/admin"
                      component={() =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/manage-permissions/admin"
                          />
                        ) : (
                          <ManagePermissionsAdmin />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/manage-permissions/members"
                      component={() =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/manage-permissions/members"
                          />
                        ) : (
                          <ManagePermissionsMembers />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/manage-permissions/participants"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/manage-permissions/participants"
                          />
                        ) : (
                          <ManagePermissionsParticipants {...props} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/private-projects"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/private-projects"
                          />
                        ) : (
                          <ManagePermissionsPrivateProject {...props} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/transactions"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname="/transactions"
                          />
                        ) : (
                          <Transactions {...props} />
                        )
                      }
                    />
                    {!this.state.loginSuccess ? (
                      <Route
                        path="/message-user/:userId"
                        component={() => (
                          <AuthModalComponent redirect={this.redirect} />
                        )}
                      />
                    ) : (
                      <Route
                        path="/message-user/:userId"
                        component={MessageToUser}
                      />
                    )}
                    <Route
                      path="/webuzz/:newsRoomId"
                      component={MessageToUser}
                    />
                    <Route path="/feeds" component={Feeds} />
                    <Route path="/how-it-works" component={How} />
                    <Route
                      path="/people-of-nature-"
                      component={PeopoleOfNature}
                    />
                    <Route
                      path="/world-people-of-nature-day/2021"
                      component={WpondStatic2021}
                    />
                    <Route
                      path="/people-of-nature-award/upcoming/:year"
                      component={WPONDUpcoming}
                      exact
                    />
                    <Route
                      path="/people-of-nature-award/Upcoming/2022/Faq"
                      component={WpondFaQPage}
                      exact
                    />
                    <Route
                      path="/people-of-nature-award/upcoming/:year/:id"
                      component={WpondUpcomingShareablePage}
                      exact
                    />
                    <Route
                      path="/people-of-nature-awards/:year/:section"
                      component={WpondAwardShareablePage}
                      exact
                    />
                    {!this.state.loginSuccess ? (
                      <Route
                        path="/people-of-nature-award/nominees/:slug/:year"
                        component={() => (
                          <AuthModalComponent redirect={this.redirect} />
                        )}
                      />
                    ) : (
                      <Route
                        path="/people-of-nature-award/nominees/:slug/:year"
                        component={AwardNomineeList}
                        exact
                      />
                    )}
                    {!this.state.loginSuccess ? (
                      <Route
                        path="/people-of-nature-award/jury/:year"
                        component={() => (
                          <AuthModalComponent redirect={this.redirect} />
                        )}
                      />
                    ) : (
                      <Route
                        path="/people-of-nature-award/:type/list/:year"
                        component={WpondItemList}
                        exact
                      />
                    )}
                    <Route
                      path="/people-of-nature-award/event/:year"
                      component={WPONDComplete}
                      exact
                    />
                    <Route
                      path="/world-people-of-nature-day"
                      component={WorldPeopleOfNatureDay}
                      exact
                    />
                    <Route
                      path="/people-of-nature-award/voters/:nomineeId"
                      component={NomineeVotersList}
                      exact
                    />
                    <Route
                      path="/story-behind-the-award"
                      component={StoryBehindAward}
                    />
                    {/*<Route path="/ceo-desk" component={CeoDesk}/>*/}
                    {/*<Route path="/the-story-behind-our-passion" component={TheStoryBehindOurPassion}/>*/}
                    {/*<Route path="/this-is-how-it-all-began" component={ThisIsHowItAllBegan}/>*/}
                    <Route
                      path="/ceo-desk"
                      render={({ match: { url } }) => (
                        <>
                          <Route path={`${url}/`} component={CeoDesk} exact />
                          <Route
                            path={`${url}/the-story-behind-our-passion/`}
                            component={TheStoryBehindOurPassion}
                            exact
                          />
                          <Route
                            path={`${url}/this-is-how-it-all-began/`}
                            component={ThisIsHowItAllBegan}
                            exact
                          />
                          <Route
                            path={`${url}/building-an-impactful-world`}
                            component={BuildingAnImpactfulWorld}
                            exact
                          />
                        </>
                      )}
                    />
                    <Route exact path="/circles">
                      <Redirect to="/communities" />
                    </Route>
                    <Route exact path="/community">
                      <Redirect to="/communities" />
                    </Route>
                    <Route
                      exact
                      path="/communities"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <UnAuthCircleList {...props} />
                        ) : (
                          <Circle {...props} />
                        )
                      }
                    />
                    <Route
                      path="/create-blog"
                      component={BlogPopupMobile}
                    />
                    <Route
                      path="/draft-blog/:id"
                      component={BlogPopupMobile}
                    />

                    {/*{!this.state.loginSuccess ? <Route path="/hidden-content"*/}
                    {/* component={() => <AuthModalComponent*/}
                    {/* redirect={this.redirect}*/}
                    {/* pathname={"/hidden-content"}/>}/> :<Route path="/hidden-content" component={HideContent}/>}*/}
                    <Route
                      path="/hidden-content"
                      render={({ match: { url } }) => (
                        <>
                          <Route
                            path={`${url}/`}
                            component={(props) =>
                              !this.state.loginSuccess ? (
                                <AuthModalComponent
                                  redirect={this.redirect}
                                  pathname={"/hidden-content/"}
                                />
                              ) : (
                                <HideContentAbout {...props} />
                              )
                            }
                            exact
                          />
                          <Route
                            path={`${url}/feeds`}
                            component={(props) =>
                              !this.state.loginSuccess ? (
                                <AuthModalComponent
                                  redirect={this.redirect}
                                  pathname={"/hidden-content/feeds"}
                                />
                              ) : (
                                <FeedsHidden {...props} />
                              )
                            }
                            exact
                          />
                          <Route
                            path={`${url}/project`}
                            component={(props) =>
                              !this.state.loginSuccess ? (
                                <AuthModalComponent
                                  redirect={this.redirect}
                                  pathname={"/hidden-content"}
                                />
                              ) : (
                                <ProjectHidden {...props} />
                              )
                            }
                            exact
                          />
                          <Route
                            path={`${url}/forum-poll`}
                            component={(props) =>
                              !this.state.loginSuccess ? (
                                <AuthModalComponent
                                  redirect={this.redirect}
                                  pathname={"/hidden-content"}
                                />
                              ) : (
                                <ForumAndPoll {...props} />
                              )
                            }
                            exact
                          />
                        </>
                      )}
                    />
                    <Route
                      path="/circle"
                      render={({ match: { url } }) => (
                        <>
                          <Route
                            path={`${url}/:slug`}
                            component={(props) => <RedirectionComponent {...props} />}
                            exact
                          />
                          <Route
                            path={`${url}/activity/:slug`}
                            component={(props) => <RedirectionComponent {...props} />}
                            exact
                          />
                          <Route
                            path={`${url}/all-activity/:slug`}
                            component={(props) => <RedirectionComponent {...props} />}
                            exact
                          />
                          <Route
                            path={`${url}/projects/:slug`}
                            component={(props) => <RedirectionComponent {...props} />}
                            exact
                          />
                          <Route
                            path={`${url}/members/:slug`}
                            component={(props) => <RedirectionComponent {...props} />}
                            exact
                          />
                          <Route
                            path={`${url}/all-members/:slug`}
                            component={(props) => <RedirectionComponent {...props} />}
                            exact
                          />
                          <Route
                            path={`${url}/stats/:slug`}
                            component={(props) => <RedirectionComponent {...props} />}
                            exact
                          />
                          <Route
                            path={`${url}/award/:slug`}
                            component={(props) => <RedirectionComponent {...props} />}
                            exact
                          />
                          <Route
                            path={`${url}/edit/:slug`}
                            component={(props) => <RedirectionComponent {...props} />}
                            exact
                          />
                        </>
                      )}
                    />
                    <Route
                      path="/community"
                      render={({ match: { url } }) => (
                        <>
                          <Route
                            path={`${url}/:slug`}
                            component={(props) =>
                              !this.state.loginSuccess ? (
                                <UnAuthAboutCircle {...props} exact />
                              ) : (
                                <AboutCircle {...props} />
                              )
                            }
                            exact
                          />
                          <Route
                            path={`${url}/activity/:slug`}
                            component={(props) =>
                              !this.state.loginSuccess ? (
                                <AuthModalComponent redirect={this.redirect} />
                              ) : (
                                <Circleactivity {...props} />
                                // <Redirect to={`${url}/${props.match.params.slug}`} />
                              )
                            }
                            exact
                          />
                          <Route
                            path={`${url}/all-activity/:slug`}
                            component={(props) =>
                              !this.state.loginSuccess ? (
                                <AuthModalComponent redirect={this.redirect} />
                              ) : (
                                <CircleViewAll {...props} />
                              )
                            }
                            exact
                          />
                          <Route
                            path={`${url}/projects/:slug`}
                            component={(props) =>
                              !this.state.loginSuccess ? (
                                <AuthModalComponent redirect={this.redirect} />
                              ) : (
                                <Circleprojects {...props} />
                              )
                            }
                            exact
                          />

                          <Route
                            path={`${url}/studio/:slug`}
                            component={(props) => <CircleStudioPage {...props} />}
                            exact
                          />
                          <Route
                            path={`${url}/article/:slug`}
                            component={(props) => <CircleArticlePage {...props} />}
                            exact
                          />
                          <Route
                            path={`${url}/members/:slug`}
                            component={(props) =>
                              !this.state.loginSuccess ? (
                                <AuthModalComponent redirect={this.redirect} />
                              ) : (
                                <Circlemembers {...props} />
                              )
                            }
                            exact
                          />
                          <Route
                            path={`${url}/all-members/:slug`}
                            component={(props) =>
                              !this.state.loginSuccess ? (
                                <AuthModalComponent redirect={this.redirect} />
                              ) : (
                                <AllMember {...props} />
                              )
                            }
                            exact
                          />
                          <Route
                            path={`${url}/stats/:slug`}
                            component={(props) =>
                              !this.state.loginSuccess ? (
                                <AuthModalComponent redirect={this.redirect} />
                              ) : (
                                <Stats {...props} />
                              )
                            }
                            exact
                          />
                          <Route
                            path={`${url}/award/:slug`}
                            component={(props) =>
                              !this.state.loginSuccess ? (
                                <AuthModalComponent redirect={this.redirect} />
                              ) : (
                                <CircleAwardsAndAccolades {...props} />
                              )
                            }
                            exact
                          />
                          <Route
                            path={`${url}/edit/:slug`}
                            component={(props) =>
                              !this.state.loginSuccess ? (
                                <AuthModalComponent redirect={this.redirect} />
                              ) : (
                                <CircleProfileHomeEditor {...props} />
                              )
                            }
                            exact
                          />

                    <Route
                      path={`${url}/hashtag/:slug`}
                      component={(props) =>
                      !this.state.loginSuccess ? (
                        <AuthModalComponent redirect={this.redirect} />
                      ) : (
                        <CircleHashTagPage {...props} />
                      )
                      }
                    exact
                    />

                    <Route
                      exact
                      path={`${url}/hashtag/:slug/:circleSlug`}
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent redirect={this.redirect} />
                        ) : (
                          <IndividualCircleHashTags {...props} />
                        )
                      }
                    />

                    <Route
                      exact
                      path={`${url}/hashtag/top-active-users/:slug/:circleSlug`}
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent redirect={this.redirect} />
                        ) : (
                          <AllCommunityHashTagsTopContributors {...props} />
                        )
                      }
                    />

                    <Route
                      exact
                      path={`${url}/hashtag/photos/:slug/:circleSlug`}
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent redirect={this.redirect} />
                        ) : (
                          <AllCommunityHashTagsPhotos {...props} />
                        )
                      }
                    />

                    <Route
                      exact
                      path={`${url}/hashtag/videos/:slug/:circleSlug`}
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent redirect={this.redirect} />
                        ) : (
                          <AllCommunityHashTagsVideos {...props} />
                        )
                      }
                    />

                    <Route
                      exact
                      path={`${url}/hashtag/blogs/:slug/:circleSlug`}
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent redirect={this.redirect} />
                        ) : (
                          <AllCommunityHashTagsBlogs {...props} />
                        )
                      }
                    />

                    <Route
                      exact
                      path={`${url}/hashtag/documents/:slug/:circleSlug`}
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent redirect={this.redirect} />
                        ) : (
                          <AllCommunityHashTagsDocuments {...props} />
                        )
                      }
                    />

                      <Route
                      exact
                      path={`${url}/hashtag/projects/:slug/:circleSlug`}
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent redirect={this.redirect} />
                        ) : (
                          <AllCommunityHashTagsProjects {...props} />
                        )
                      }
                    />

                    <Route
                      exact
                      path={`${url}/hashtag/studios/:slug/:circleSlug`}
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent redirect={this.redirect} />
                        ) : (
                          <AllCommunityHashTagsStudios {...props} />
                        )
                      }
                    />

                    <Route
                      exact
                      path={`${url}/hashtag/others/:slug/:circleSlug`}
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent redirect={this.redirect} />
                        ) : (
                          <AllCommunityHashTagsOthers {...props} />
                        )
                      }
                    />

                        </>
                      )}
                    />

                    {!this.state.loginSuccess ? (
                      <Route
                        path="/connected"
                        component={() => (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname={"/connected"}
                          />
                        )}
                      />
                    ) : (
                      <Route path="/connected" component={connected} />
                    )}
                    <Route
                      exact
                      path="/our-solution"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent redirect={this.redirect} pathname={"/our-solution"} />
                        ) : (
                          <OurSoliutionList {...props} />
                        )
                      }
                    />
                    <Route exact path="/circle-page">
                      <Redirect to="/community-page" />
                    </Route>
                    <Route
                      exact
                      path="/community-page"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent redirect={this.redirect} pathname={"/community-page"} />
                        ) : (
                          <CirlePage {...props} />
                        )
                      }
                    />
                    {!this.state.loginSuccess ? (
                      <Route
                        path="/organization"
                        component={() => (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname={"/organization"}
                          />
                        )}
                      />
                    ) : (
                      <Route path="/organization" component={organizationPage} />
                    )}
                    {!this.state.loginSuccess ? (
                      <Route
                        path="/following"
                        component={() => (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname={"/following"}
                          />
                        )}
                      />
                    ) : (
                      <Route path="/following" component={following} />
                    )}
                    {!this.state.loginSuccess ? (
                      <Route
                        path="/pages"
                        component={() => (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname={"/pages"}
                          />
                        )}
                      />
                    ) : (
                      <Route path="/pages" component={pages} />
                    )}
                    {!this.state.loginSuccess ? (
                      <Route
                        path="/network-groups"
                        component={() => (
                          <AuthModalComponent
                            redirect={this.redirect}
                            pathname={"/network-groups"}
                          />
                        )}
                      />
                    ) : (
                      <Route path="/network-groups" component={groups} />
                    )}
                    <Route path="/unsubscribe" component={unsubscribe} />
                    {/* <Route path="/wall" component={ProjectFeedsPhotoDetails} /> */}
                    <Route path="/how-we-impacts" component={HowWeImpact} exact />
                    <Route path="/how-we-impacts/:categorySlug" component={HowWeImpactCategory} exact />
                    <Route path="/how-we-impacts/:categorySlug/:categoryArticleSlug" component={HowWeImpactCategoryArticle} exact />
                    <Route path="/how-we-impacts/:categorySlug/find-how/:categoryArticleSlug" component={HowWeImpactFindHow} exact />

                    <Route
                      exact
                      path="/dashboard"
                      component={(props) =>
                        !this.state.loginSuccess ? (
                          <AuthModalComponent redirect={this.redirect} pathname={"/dashboard"} />
                        ) : (
                          <BadgeDashboard {...props} />
                        )
                      }
                    />

                    <Route
                      render={(props) => (
                        <div className="wrapper nologinpage staticPage p404">
                          {!this.state.loginSuccess ? (
                            <StaticHeader display={true} notFound={true} />
                          ) : (
                            <Header EditedText={true} />
                          )}
                          <main className="dgcontainer hm3col">
                            <div className="container">
                              <NotFound />
                            </div>
                          </main>
                          {!this.state.loginSuccess ? (
                            <LeftPanelUnauth
                              signUpRequired={this.signUpRequired}
                            />
                          ) : (
                            <LeftPanel />
                          )}
                          {!this.state.loginSuccess ? <FooterDiv /> : ""}
                          {this.state.loginRequired === true ? (
                            <AuthModalComponent
                              handleLoginClose={this.handleLoginClose}
                              pathname={this.state.pathname}
                              history={this.props.history}
                            />
                          ) : null}
                        </div>
                      )}
                    />                   
                  </Switch>
                </ErrorBoundary>
              </ScrollToTop>
            </Suspense>
          </Router>
        </Provider>
        <CookieAcceptance />
        <Download />
        {/*{this.state.loginSuccess &&
 <Promotion/>}*/}
      </div>
    );
  }
}

ReactDOM.render(<App />, document.getElementById("root"));
