import React, { memo } from 'react'
import { authService } from "./auth";
import $ from 'jquery';

import httpService from './AxiosInterceptors';
import axios from 'axios';
import UserSearchItem from "./search/UserSearchItem";
import Loader from 'react-loader-spinner';
import InfiniteScroll from "react-infinite-scroller";
import CauseSearchItem from "./search/CauseSearchItem";
import ProjectSearchItem from "./search/ProjectSearchItem";
import CompanySearchItem from "./search/CompanySearchItem";
import ArticleSearchItem from "./search/ArticleSearchItem";
import FeedSearchItem from "./search/FeedSearchItem";
import GroupSearchItem from "./search/GroupSearchItem";
import { setUserData } from "./reducer/auth";
import Notification from "./notification/Notification";
import { connect } from "react-redux";
import { Link, Redirect, withRouter } from "react-router-dom";
import { messageReceivedFulfilled, requestNotificationReceivedFulfilled } from "./reducer/notificationsReducer";
import { setProfileData } from "./reducer/profile/ProfileReducer";
import { getCDNUrl, isMobileDevice, restrictedPageRedirection, userAgentGetData } from "./commons/CommonFunctions";
import CircleSearchItem from "./search/CircleSearchItem";
import { Scrollbars } from 'react-custom-scrollbars';
import HorizontalScroll from 'react-scroll-horizontal';
import debounce from 'lodash.debounce';
import Chat from "./FaqChat/chat";
import ProjectDetails from "./project/component/commons/ProjectDetails";
import { setWpondYearData } from './reducer/wpond/WpondReducer';
import HashtagSearchItem from './search/HashtagSearchItem';
import CleverTapUtils from './commons/CleverTapUtils';
import ReactTooltip from 'react-tooltip';

if (isMobileDevice()) {
    import('../src/commons/styles/responsive-useragent.css').then(() => {
        console.log("Imported mobile css");
    });
}

var stompClient = null;

httpService.setupInterceptors();

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: authService.isAuthenticated(),
            userData: authService.getUserDetailsFromLocalStorage(),
            recentSearches: [],
            searchclause: '',
            searchOption: 'PEOPLE',
            searchData: [],
            searchMaxLength: 7,
            profileData: {},
            year: "",
            adminAccess: false
        }
        this.handleAutoComplete = debounce(this.handleAutoComplete.bind(this), 700);
    }


    getOptimizedImage(personalInfo) {
        if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages['200x200'] != null) {
            return personalInfo.resizedProfileImages['200x200'];
        }
        return personalInfo.profileImage;
    }

    getMenuData = () => {
        this.getAdminAccessDetails();
    }

    getUserDataDetails = (userId) => {
        axios({
          method: 'GET',
          url: `${process.env.REACT_APP_userServiceURL}/profile/basic-info?userId=${userId}`,
          withCredentials: true
        }).then((response)=>{
            if(response && response.data && response.data.body && response.data.message === "Success!"){
                let userData = this.state.userData;
                if(userData.firstName !== response.data.body.name && userData.companyName !== response.data.body.name) {
                    if(userData.type === "COMPANY") {
                        userData.companyName = response.data.body.name;
                    } else {
                        userData.firstName = response.data.body.name;
                    }
                    authService.updateUserData(userData);
                }
            }
        }).catch((error)=>{
        })
    }

    componentDidMount() {
        if (this.state.userData && this.state.userData.userId) {
            this.getUserProfileData();
            this.getUserDataDetails(this.state.userData.userId);
        }
        this.props.setWpondYearData(true);
        window.$(document).on('contextmenu', "img:not('.logotop')", function () {
            return false;
        })
        window.$('.usermenu .dropdown-submenu > a').on("click", function (e) {
            var submenu = $(this);
            $('.usermenu .dropdown-submenu .dropdown-menu').removeClass('show');
            submenu.next('.usermenu .dropdown-menu').addClass('show');
            e.stopPropagation();
        });
        window.$('.collapser').click(function () {
            window.$(this).next().collapse('toggle');
        });
        window.$('.profeditrow .edtbtn.collapser').click(function () {
            window.$(".profeditrow").removeClass("current");
            window.$(".profeditmain").removeClass('show');
            // eslint-disable-next-line no-restricted-globals
            window.$(event.target).closest(".profeditrow").addClass("current");
        });
        window.$('.profeditrow .btn-cancel').click(function () {
            // eslint-disable-next-line no-restricted-globals
            window.$(event.target).closest(".profeditrow").removeClass("current");
            //$('.profeditmain').removeClass('show');
            // eslint-disable-next-line no-restricted-globals
            window.$(event.target).closest(".profeditmain").removeClass('show');
        });

        let $input = $(".search_query");

        $('.search-toggle').on("click", function () {
            $(this).parent(".search_block_top1").toggleClass("active-search");
            $("#header").addClass("searchact");
            $input.trigger("");
        });
        $('.search_block_top1').find('.search-close').on("click", function () {
            $('.topsearch').removeClass('active');
            $(".search_block_top1").removeClass("active-search");
            $("#header").removeClass("searchact");
            $input.val('');
        });

        $(".topsearch form .form-control").on('click', function (event) {
            $('.topsearch').addClass('active');
            document.body.classList.add('searchDropActive');
        });
        $(document).on('click', function (event) {
            if (!$(event.target).closest('.topsearch').length) {
                $('.topsearch').removeClass('active');
                document.body.classList.remove('searchDropActive');
                $(".search_block_top1").removeClass("active-search");
                $("#header").removeClass("searchact");
            }
        });

        window.$(".usernotification .btn.point").on('click', function (event) {
            window.$('.usernotification').addClass('active');
        });
        window.$(document).on('click', function (event) {
            if (!window.$(event.target).closest('.usernotification').length) {
                window.$('.usernotification').removeClass('active');
            }
        });

        window.$('#header .iCross').hide();
        window.$("#header .iHamburger").on('click', function (event) {
            window.$('.leftpanel').addClass('navactive');
            if (document.getElementsByClassName('leftpanel').length > 0) {
                window.$('.dgcontainer').addClass('navactive');
            }
            window.$('.staticPage').addClass('navactive');
            window.$('#header .iHamburger').hide();
            window.$('#header .iCross').show();
        });
        $("body, html").on('click', function (event) {
            if (!$(event.target).closest('#header .iHamburger, .read-more-less-btn').length) {
                $('.leftpanel').removeClass('navactive');
                $('.dgcontainer').removeClass('navactive');
                window.$('.staticPage').removeClass('navactive');
                $('.dropdown-menu.show').removeClass('show');
                window.$('#header .iHamburger').show();
                window.$('#header .iCross').hide();
            }
        });
        window.$('.topDropdownMob .dropdown > a').on("click", function (e) {
            // var submenu = $(this);
            // $('.topDropdownMob .dropdown-menu').removeClass('show');
            // submenu.next('.topDropdownMob .dropdown-menu').addClass('show');
            e.stopPropagation();
            $(this).next('.dropdown-menu').toggleClass('show');
        });

        window.$('.modal').on('hidden.bs.modal', function () {
            if ($(".modal:visible").length > 0) {
                setTimeout(function () {
                    window.$('body').addClass('modal-open');
                }, 200)
            }
        });

        this.fetchRecentSearches(this.state.searchOption);
        this.connectForRealTimeNotifications();
    }

    componentWillReceiveProps(nextProps) {
        if ((window.location.pathname === '/message' || window.location.pathname === '/message-user') && isMobileDevice()) {
            this.setState({
                pathname: 'message'
            })
        }
        if(this.props.getYearDetails && this.props.yearData?.year) {
            this.props.getYearDetails(this.props.yearData);
        }
        // if (nextProps.userData !== this.state.userData) {
        //     this.getUserProfileData();
        // }

    }

    getUserProfileData() {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/profile/get?id=' + this.state.userData.userId+'&otherUserId='+this.state.userData.userId+userAgentGetData(),
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.message === 'Success!') {
                this.setState({ 'profileData': response.data.body }, () => {
                    if (this.state.profileData.deactivated) {
                        window.location.href = "/signout";
                    }
                });
            }
        }).catch((err) => {
            console.log(err);
            if(err && err.response && err.response.data && err.response.data.statusCode === 403) {
                restrictedPageRedirection();
            }
        });
    }

    getAdminAccessDetails = () => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/adminPanelUser/getPermission?userId=${this.state.userData.userId}`,
            withCredentials: true,
        })
            .then((response) => {
                if (
                    response &&
                    response.data &&
                    response.data.body &&
                    response.data.message === "Success!"
                ) {
                    if(response.data.body) {
                        this.setState({
                            adminAccess: true
                        })
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    connectForRealTimeNotifications = () => {

        if (stompClient !== undefined && stompClient !== null) {
            //console.log('stompClient already connected');
            return false;
        }

        let token = null;
        this.socketServetConnect('');

        /*axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/api/authenticate?username=' + process.env.REACT_APP_authUser + '&password=' + process.env.REACT_APP_authSecret,
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then((response) => {
            token = response.headers.authorization;
            this.socketServetConnect(token);
        }).catch((e) => {
            console.log(e);
        });*/

    };


    socketServetConnect(token) {
        const Stomp = require('stompjs')

        stompClient = Stomp.client(process.env.REACT_APP_socketURL);
        stompClient.debug = null;

        stompClient.reconnect_delay = 5000;
        stompClient.heartbeat.outgoing = 20000;
        stompClient.heartbeat.incoming = 20000;

        var headers = { "X-Authorization": token };
        stompClient.connect(headers, this.onConnected, this.onError);
    }


    onConnected = () => {
        stompClient.subscribe('/topic/' + this.state.userData.userId + '.notifications', this.onMessageReceived);

        var subsUser = 'Private-' + this.state.userData.userId;
        stompClient.subscribe('/topic/' + subsUser, this.onChatMessageReceived);

        axios.get(process.env.REACT_APP_userServiceURL + '/messaging/message_service/api/message_group/ByTypeANDId?messageGroupType=MemberGroup&userId=' + this.state.userData.userId, { withCredentials: true }).then(response => {
            if (response && response.data.body !== null) {
                let groupListTemp = Object.values(response.data.body);
                if (groupListTemp !== null && groupListTemp !== undefined && groupListTemp.length > 0) {
                    for (var eachGroup of groupListTemp) {
                        var eachGroupId = eachGroup.id;
                        var roomId = 'MemberGroup-' + eachGroupId;
                        if (stompClient) {
                            stompClient.subscribe('/topic/' + roomId, this.onChatMessageReceived);
                        }
                    }
                }
            }
        });
    }

    onError = (e) => {
        console.log(e);
        if (typeof this.reConnectWebSocket === 'function') {
            if (stompClient !== undefined && stompClient !== null) {
                stompClient.disconnect(function () {
                    console.log("Existing connection disconnected");
                });
            }
            stompClient = null;
            this.reConnectWebSocket();
        }
    }

    reConnectWebSocket() {
        console.log('Reconnecting.....');

        if (typeof this.connectForRealTimeNotifications === 'function') {
            setTimeout(() => {
                this.connectForRealTimeNotifications();
            }, 5000);
        }
    }


    onMessageReceived = (payload) => {
        this.props.requestNotificationReceivedFulfilled({ success: true, payload: payload });
    }

    onChatMessageReceived = (payload) => {
        this.props.messageReceivedFulfilled({ success: true, payload: payload });
    }

    fetchRecentSearches = (searchType) => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/search/' + this.state.userData.userId
                + '/recentSearches?size=3&searchType=' + searchType,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.message === 'Success!') {
                this.setState({ 'recentSearches': response.data.body });
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    selectRecentSearchOption = (item) => {
        this.setState({ 'searchclause': item });
        this.refs.Searchbtn.click();
    }

    handleSearchSubmit = (event) => {
        event.preventDefault();

        if (this.state.searchclause !== undefined && this.state.searchclause.length > 2) {
            this.setState({ 'searching': true });
            let selectedOption = this.state.searchOption;

            if (selectedOption === '') {
                selectedOption = 'PEOPLE';
            }

            let data = {};

            this.getNavigatorGeoLocation().then((coordinates) => {
                if (coordinates.latitude && coordinates.longitude) {
                    data = {
                        searchKeyword: this.state.searchclause,
                        searchType: selectedOption,
                        latitude: coordinates.latitude,
                        longitude: coordinates.longitude
                    }
                } else {
                    alert("Sorry, your browser does not support geolocation. Use other modern browsers for better experience");
                    data = {
                        searchKeyword: this.state.searchclause,
                        searchType: selectedOption
                    }
                }
                this.callSearchApi(data, selectedOption, 0, this.state.searchMaxLength, false);

            }).catch(() => {
                data = {
                    searchKeyword: this.state.searchclause,
                    searchType: selectedOption
                }
                this.callSearchApi(data, selectedOption, 0, this.state.searchMaxLength, false);
            });
        }
    };

    callSearchApi = (data, selectedOption, page, size, concatWithPrevious) => {
        this.setState({ isAutoComplete: false });
        if (!concatWithPrevious) {
            this.setState({ searchData: [] });
        }

        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/search/' + this.state.userData.userId
                + '?page=' + page + '&size=' + size,
            data: data,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.status === 200) {
                this.fetchRecentSearches(selectedOption);

                let pagedData = response.data.body.page;

                if (pagedData && pagedData.content) {
                    let details = [];
                    if (selectedOption === 'PEOPLE') {
                        pagedData.content.forEach((userId) => {
                            response.data.body.content.forEach((userElement) => {
                                if (userElement.userId === userId) {
                                    details.push({
                                        profileId: userId,
                                        firstName: userElement.firstName,
                                        lastName: userElement.lastName,
                                        profileImage: userElement.profileImage,
                                        persona: userElement.persona,
                                        connected: userElement.connected,
                                        customUrl: userElement.customUrl
                                    });
                                }
                            });
                        });

                    } else if (selectedOption === 'COMPANY') {
                        pagedData.content.forEach((userId) => {
                            response.data.body.content.forEach((userElement) => {
                                if (userElement.userId === userId) {
                                    details.push({
                                        profileId: userId,
                                        companyName: userElement.companyName,
                                        profileImage: userElement.profileImage,
                                        customUrl: userElement.customUrl
                                    });
                                }
                            });
                        });

                    } else if (selectedOption === 'CAUSES') {
                        pagedData.content.forEach((id) => {
                            response.data.body.content.forEach((element) => {
                                if (element.id === id) {
                                    details.push({
                                        id: id,
                                        name: element.name,
                                        imageUrl: element.imageUrl,
                                        resizedImages: element.resizedImages,
                                        location: element.country ? element.country : null,
                                        hashtags: element.hashtags,
                                        slug: element.slug
                                    });
                                }
                            });
                        });

                    } else if (selectedOption === 'FEEDS') {
                        pagedData.content.forEach((postId) => {
                            response.data.body.content.forEach((element) => {
                                if (element.id === postId) {
                                    details.push({
                                        postId: postId,
                                        userId: element.userId,
                                        userType: element.userType,
                                        userName: element.userName,
                                        postType: element.postType,
                                        attachmentIds: element.attachmentIds,
                                        description: element.description,
                                        userProfileImage: element.userProfileImage,
                                        userEntityType: element.userEntityType,
                                        params: element.params,
                                        createTime: element.createTime
                                    });
                                }
                            });
                        });

                    } else if (selectedOption === 'ARTICLES') {
                        pagedData.content.forEach((postId) => {
                            response.data.body.content.forEach((element) => {
                                if (element.id === postId) {
                                    details.push({
                                        postId: postId,
                                        postType: element.postType,
                                        userId: element.userId,
                                        userType: element.userType,
                                        userName: element.userName,
                                        attachmentIds: element.attachmentIds,
                                        title: element.title,
                                        userProfileImage: element.userProfileImage,
                                        userEntityType: element.userEntityType,
                                        params: element.params,
                                        postLinkTypeUrl: element.postLinkTypeUrl,
                                        createTime: element.createTime,
                                        slug: element.slug
                                    });
                                }
                            });
                        });
                    } else if (selectedOption === 'PROJECTS') {
                        pagedData.content.forEach((detail) => {
                            response.data.body.content.forEach((element) => {
                                if (element.projectId === detail._id) {
                                    details.push({
                                        id: detail._id,
                                        title: element.title,
                                        slug: element.slug,
                                        feedPageShow: element.feedPageShow,
                                        coverImage: element.coverImage,
                                        location: element.country ? element.country : null,
                                        resizedImages: element.project ? element.project.resizedImages : null
                                    });
                                }
                            });
                        });

                    } else if (selectedOption === 'CIRCLE') {
                        pagedData.content.forEach((circleId) => {
                            response.data.body.content.forEach((element) => {
                                if (element.id === circleId) {
                                    details.push({
                                        circleId: circleId,
                                        title: element.title,
                                        profileImage: element.profileImage,
                                        resizedProfileImages: element.resizedProfileImages,
                                        slug: element.slug,
                                        location: element.location && element.location.country ? element.location.country : null,
                                    });
                                }
                            });
                        });
                    } else if (selectedOption === 'HASHTAG') {
                        response.data.body && response.data.body.content && response.data.body.content.map((element, index) => {
                            details.push({ tagsId: index, tags: element })
                        })
                    }

                    pagedData.type = selectedOption;
                    pagedData.content = details;

                    if (concatWithPrevious === true && this.state.searchData && this.state.searchData.content) {
                        pagedData.content = this.state.searchData.content.concat(pagedData.content);
                    }
                    this.setState({
                        searchData: response.data.body.page,
                        searching: false,
                        previousSearch: data,
                        isSearchResultLoading: false
                    });
                } else {
                    pagedData = {};
                    pagedData.totalElements = 0;
                    pagedData.type = selectedOption;
                    pagedData.content = [];

                    this.setState({
                        searchData: pagedData,
                        searching: false,
                        isSearchResultLoading: false
                    });
                }
            }
        }).catch((err) => {
            console.log(err);
            this.setState({
                searching: false,
                isSearchResultLoading: false
            });
        });
    }

    getNavigatorGeoLocation = () => {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    resolve(position.coords);
                }, (err) => {
                    reject(err);
                });
            } else {
                resolve();
            }
        });
    }

    handleSearchKeywordChange = (e) => {
        this.setState({ searchclause: e.target.value }, () => {
            this.handleAutoComplete();
        });
    };

    handleAutoComplete = () => {

        if (this.state.searchclause) {
            let selectedOption = this.state.searchOption;

            if (selectedOption === '') {
                selectedOption = 'PEOPLE';
            }

            let data = {
                searchKeyword: this.state.searchclause.replace(/^#/g, ""),
                searchType: selectedOption
            }

            this.callSearchSuggestionsApi(data, selectedOption, 0, this.state.searchMaxLength, false);
        }
    };

    callSearchSuggestionsApi = (data, selectedOption, page, size, concatWithPrevious) => {
        this.setState({ isAutoComplete: true });
        if (!concatWithPrevious) {
            this.setState({ searchData: [] });
        }

        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/search/suggestions/' + this.state.userData.userId
                + '?query=' + data.searchKeyword + '&searchType=' + selectedOption + '&page=' + page + '&size=' + size,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.status === 200) {

                let pagedData = response.data.body.page;

                if (pagedData && pagedData.content) {
                    let details = [];
                    if (selectedOption === 'PEOPLE') {
                        pagedData.content.forEach((userId) => {
                            response.data.body.content.forEach((userElement) => {
                                if (userElement.userId === userId) {
                                    details.push({
                                        profileId: userId,
                                        firstName: userElement.firstName,
                                        lastName: userElement.lastName,
                                        profileImage: userElement.profileImage,
                                        persona: userElement.persona,
                                        connected: userElement.connected,
                                        customUrl: userElement.customUrl
                                    });
                                }
                            });
                        });

                    } else if (selectedOption === 'COMPANY') {
                        pagedData.content.forEach((userId) => {
                            response.data.body.content.forEach((userElement) => {
                                if (userElement.userId === userId) {
                                    details.push({
                                        profileId: userId,
                                        companyName: userElement.companyName,
                                        profileImage: userElement.profileImage,
                                        customUrl: userElement.customUrl
                                    });
                                }
                            });
                        });

                    } else if (selectedOption === 'CAUSES') {
                        pagedData.content.forEach((id) => {
                            response.data.body.content.forEach((element) => {
                                if (element.id === id) {
                                    details.push({
                                        id: id,
                                        name: element.name,
                                        imageUrl: element.imageUrl,
                                        resizedImages: element.resizedImages,
                                        location: element.country ? element.country : null,
                                        hashtags: element.hashtags,
                                        slug: element.slug
                                    });
                                }
                            });
                        });

                    } else if (selectedOption === 'FEEDS') {
                        pagedData.content.forEach((postId) => {
                            response.data.body.content.forEach((element) => {
                                if (element.id === postId) {
                                    details.push({
                                        postId: postId,
                                        userId: element.userId,
                                        userType: element.userType,
                                        userName: element.userName,
                                        postType: element.postType,
                                        attachmentIds: element.attachmentIds,
                                        description: element.description,
                                        userProfileImage: element.userProfileImage,
                                        userEntityType: element.userEntityType,
                                        params: element.params,
                                        createTime: element.createTime
                                    });
                                }
                            });
                        });

                    } else if (selectedOption === 'ARTICLES') {
                        pagedData.content.forEach((postId) => {
                            response.data.body.content.forEach((element) => {
                                if (element.id === postId) {
                                    details.push({
                                        postId: postId,
                                        postType: element.postType,
                                        userId: element.userId,
                                        userType: element.userType,
                                        userName: element.userName,
                                        attachmentIds: element.attachmentIds,
                                        title: element.title,
                                        userProfileImage: element.userProfileImage,
                                        userEntityType: element.userEntityType,
                                        params: element.params,
                                        postLinkTypeUrl: element.postLinkTypeUrl,
                                        createTime: element.createTime,
                                        slug: element.slug
                                    });
                                }
                            });
                        });
                    } else if (selectedOption === 'PROJECTS') {
                        pagedData.content.forEach((detail) => {
                            response.data.body.content.forEach((element) => {
                                if (element.projectId === detail._id) {
                                    details.push({
                                        id: detail._id,
                                        title: element.title,
                                        slug: element.slug,
                                        feedPageShow: element.feedPageShow,
                                        coverImage: element.coverImage,
                                        location: element.country ? element.country : null,
                                        resizedImages: element.project ? element.project.resizedImages : null
                                    });
                                }
                            });
                        });

                    } else if (selectedOption === 'CIRCLE') {
                        pagedData.content.forEach((circleId) => {
                            response.data.body.content.forEach((element) => {
                                if (element.id === circleId) {
                                    details.push({
                                        circleId: circleId,
                                        title: element.title,
                                        profileImage: element.profileImage,
                                        resizedProfileImages: element.resizedProfileImages,
                                        slug: element.slug,
                                        location: element.location && element.location.country ? element.location.country : null,
                                    });
                                }
                            });
                        });
                    } else if (selectedOption === 'HASHTAG') {
                        response.data.body.content.map((element, index) => {
                            details.push({ tagsId: index, tags: element })
                        })
                    }

                    pagedData.type = selectedOption;
                    pagedData.content = details;

                    if (concatWithPrevious === true && this.state.searchData && this.state.searchData.content) {
                        pagedData.content = this.state.searchData.content.concat(pagedData.content);
                    }
                    this.setState({
                        searchData: response.data.body.page,
                        previousSearch: data,
                        isSearchResultLoading: false
                    });
                } else {
                    pagedData = {};
                    pagedData.totalElements = 0;
                    pagedData.type = selectedOption;
                    pagedData.content = [];

                    this.setState({
                        searchData: pagedData,
                        isSearchResultLoading: false
                    });
                }
            }
        }).catch((err) => {
            console.log(err);
            this.setState({
                isSearchResultLoading: false
            });
        });
    }

    selectSearchOption = (type) => {
        this.fetchRecentSearches(type);
        this.setState({ searchOption: type });
    };

    fetchMoreSearchData() {
        let searchdata = this.state.searchData;

        if (this.state.previousSearch) {
            this.setState({ isSearchResultLoading: true });
            let pageable = searchdata.pageable;
            if (!this.state.isAutoComplete) {
                this.callSearchApi(this.state.previousSearch, this.state.searchOption, pageable.pageNumber + 1, this.state.searchMaxLength, true);
            } else {
                this.callSearchSuggestionsApi(this.state.previousSearch, this.state.searchOption, pageable.pageNumber + 1, this.state.searchMaxLength, true);
            }
        }
    }

    redirectToUserDashboard = () => {
        if(this.props.location?.pathname === "/dashboard-settings" && this.props.location?.state?.userId) {
            window.open("/dashboard-settings", '_blank');
        } else {
            this.props.history.push({
                pathname: "/dashboard-settings"
            })
        }
    }

    render() {
        const parent = { height: `33px` }
        if (!this.state.loginSuccess) {
            return <Redirect to='/' />
        } else {
            return <header id="header" className={this.props.EditedText ? "errpg404" : ""}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 col-lg-3 logowrap">
                            <div className="logotop">
                                <a href="/">
                                    <img className="logotop"
                                        src='https://cdn.dscovr.com/images/logoWeNaturalists.svg'
                                        alt="" />
                                </a>
                            </div>

                        </div>
                        <div className="col-md-9 col-lg-9 htoprt">
                            <div className="topsearch search_block_top1">
                                <span className="search-toggle" />
                                <form className="searchbox" onSubmit={this.handleSearchSubmit}>
                                    <input type="text" className="search_query form-control" id="searchclause"
                                        name="searchclause" disabled={this.state.searching}
                                        value={this.state.searchclause || ''}
                                        onChange={this.handleSearchKeywordChange}
                                        placeholder="Search" autoComplete="off" maxLength={75} />
                                    {
                                        this.state.searching ?
                                            <div>
                                                <Loader
                                                    type="ThreeDots"
                                                    color="#00394D"
                                                    height={20}
                                                    width={40}
                                                    timeout={6000}

                                                />
                                            </div>
                                            : <button className="submit" ref="Searchbtn"><i className="fa fa-search"
                                                aria-hidden="true" />
                                            </button>
                                    }
                                    {/*<span className="search-close"></span>*/}
                                </form>
                                <div className="topsearchmisc fadeIn animated">
                                    {
                                        this.state.recentSearches.length > 0 &&
                                        <div className="searchrecent">
                                            <h6>Recent</h6>
                                            <div className="searchrecentlist clearfix">
                                                {
                                                    this.state.recentSearches.map((item, index) =>
                                                        (index < 3) &&
                                                        <span
                                                            className={this.state.searchclause === item ? 'active' : ''}
                                                            onClick={() => this.selectRecentSearchOption(item)}
                                                            key={index}>{item}</span>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    }
                                    <div className="tablist">
                                        <HorizontalScroll reverseScroll
                                            style={parent}
                                            className="searchtaglist">
                                            <div className='nav clearfix'>
                                                <span data-target="#sech-people" data-toggle="tab"
                                                    onClick={() => this.selectSearchOption('PEOPLE')}
                                                    className="active">People</span>
                                                <span data-target="#sech-hashtags" data-toggle="tab"
                                                    onClick={() => this.selectSearchOption('HASHTAG')}>Hashtag</span>                                                
                                                <span data-target="#sech-circles" data-toggle="tab"
                                                    onClick={() => this.selectSearchOption('CIRCLE')}>Communities</span>
                                                <span data-target="#sech-projects" data-toggle="tab"
                                                    onClick={() => this.selectSearchOption('PROJECTS')}>Projects</span>
                                                <span data-target="#sech-company" data-toggle="tab"
                                                    onClick={() => this.selectSearchOption('COMPANY')}>Organization</span>
                                                <span data-target="#sech-articles" data-toggle="tab"
                                                    onClick={() => this.selectSearchOption('ARTICLES')}>Explore</span>
                                                <span data-target="#sech-causes" data-toggle="tab"
                                                    onClick={() => this.selectSearchOption('CAUSES')}>Causes</span>
                                                {/*<span data-target="#sech-groups" data-toggle="tab"
                                                onClick={() => this.selectSearchOption('groups')}>Groups</span>*/}
                                                <span data-target="#sech-feeds" data-toggle="tab"
                                                    onClick={() => this.selectSearchOption('FEEDS')}>Feeds</span>
                                            </div>
                                        </HorizontalScroll>
                                        <div className='arrowDirectionView'>
                                            {/* <i className='iLeftSlide' /> Scroll <i className='iRightSlide' /> */}
                                            <img src="https://cdn.dscovr.com/images/arrowDirectionV2.gif" alt="" />
                                        </div>
                                    </div>
                                    <div id="tabsContent" className="tab-content">
                                        <div id="sech-people" className="tab-pane fade active show">
                                            {
                                                this.state.searchOption === 'PEOPLE' && this.state.searchData &&
                                                this.state.searchData.type === 'PEOPLE' && this.state.searchData.content &&
                                                <>
                                                    <div className="srch-filter">
                                                        <div className="results"><i className="iWN_Network_OL" />
                                                            {this.state.searchData.totalElements} Results
                                                        </div>
                                                    </div>
                                                    <div className='srchresultScrollbar'>
                                                        <div className={'srchresultwrap people topsearchmisc-outer-wrapper'} style={{height: "260px", overflow: "auto"}}>
                                                            {
                                                                this.state.searchData.content && this.state.searchData.content.length > 0
                                                                && <InfiniteScroll
                                                                    loadMore={this.fetchMoreSearchData.bind(this)}
                                                                    getScrollParent={() => this.scrollParentPeopleRef}
                                                                    hasMore={!this.state.isSearchResultLoading && !this.state.searchData.last}
                                                                    //loader={<h4>Loading...</h4>}
                                                                    useWindow={false}
                                                                >
                                                                    {
                                                                        this.state.searchData.content.map((item) => {
                                                                            return <UserSearchItem item={item}
                                                                                                   key={item.profileId} />
                                                                        })
                                                                    }
                                                                </InfiniteScroll>
                                                            }
                                                        </div>
                                                        <div class="topsearchmisc-pseduo-track"></div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div id="sech-causes" className="tab-pane fade">
                                            {
                                                this.state.searchOption === 'CAUSES' && this.state.searchData &&
                                                this.state.searchData.type === 'CAUSES' && this.state.searchData.content &&
                                                <>
                                                    <div className="srch-filter">
                                                        <div className="results">
                                                            <i className="iHashtag" />
                                                            {this.state.searchData.totalElements} Results
                                                        </div>
                                                    </div>
                                                    <div className='srchresultScrollbar'>
                                                        <div className={'srchresultwrap people topsearchmisc-outer-wrapper'} style={{height: "260px", overflow: "auto"}}>
                                                            {
                                                                this.state.searchData.content && this.state.searchData.content.length > 0
                                                                && <InfiniteScroll
                                                                    loadMore={this.fetchMoreSearchData.bind(this)}
                                                                    getScrollParent={() => this.scrollParentCauseRef}
                                                                    hasMore={!this.state.isSearchResultLoading && !this.state.searchData.last}
                                                                    loader={<h4>Loading...</h4>}
                                                                    useWindow={false}
                                                                >
                                                                    {
                                                                        this.state.searchData.content.map((item) => {
                                                                            return <CauseSearchItem item={item}
                                                                                key={item.id} />
                                                                        })
                                                                    }
                                                                </InfiniteScroll>
                                                            }
                                                        </div>
                                                        <div class="topsearchmisc-pseduo-track"></div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div id="sech-projects" className="tab-pane fade">
                                            {
                                                this.state.searchOption === 'PROJECTS' && this.state.searchData &&
                                                this.state.searchData.type === 'PROJECTS' && this.state.searchData.content &&
                                                <>
                                                    <div className="srch-filter">
                                                        <div className="results">
                                                            <i className="iWN_Projects_OL" />
                                                            {this.state.searchData.totalElements} Results
                                                        </div>
                                                    </div>
                                                    <div className='srchresultScrollbar'>
                                                        <div className={'srchresultwrap people topsearchmisc-outer-wrapper'} style={{height: "260px", overflow: "auto"}}>
                                                            {
                                                                this.state.searchData.content && this.state.searchData.content.length > 0
                                                                && <InfiniteScroll
                                                                    loadMore={this.fetchMoreSearchData.bind(this)}
                                                                    getScrollParent={() => this.scrollParentProjectRef}
                                                                    hasMore={!this.state.isSearchResultLoading && !this.state.searchData.last}
                                                                    loader={<h4>Loading...</h4>}
                                                                    useWindow={false}
                                                                >
                                                                    {
                                                                        this.state.searchData.content.map((item) => {
                                                                            return <ProjectSearchItem item={item}
                                                                                key={item.id} />
                                                                        })
                                                                    }
                                                                </InfiniteScroll>
                                                            }
                                                        </div>
                                                        <div class="topsearchmisc-pseduo-track"></div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div id="sech-company" className="tab-pane fade">
                                            {
                                                this.state.searchOption === 'COMPANY' && this.state.searchData &&
                                                this.state.searchData.type === 'COMPANY' && this.state.searchData.content &&
                                                <>
                                                    <div className="srch-filter">
                                                        <div className="results">
                                                            <i className="iProjects_F" />
                                                            {this.state.searchData.totalElements} Results
                                                        </div>
                                                    </div>
                                                    <div className='srchresultScrollbar'>
                                                        <div className={'srchresultwrap people topsearchmisc-outer-wrapper'} style={{height: "260px", overflow: "auto"}}>
                                                            {
                                                                this.state.searchData.content && this.state.searchData.content.length > 0
                                                                && <InfiniteScroll
                                                                    loadMore={this.fetchMoreSearchData.bind(this)}
                                                                    getScrollParent={() => this.scrollParentCompanyRef}
                                                                    hasMore={!this.state.isSearchResultLoading && !this.state.searchData.last}
                                                                    loader={<h4>Loading...</h4>}
                                                                    useWindow={false}
                                                                >
                                                                    {
                                                                        this.state.searchData.content.map((item) => {
                                                                            return <CompanySearchItem item={item}
                                                                                key={item.profileId} />
                                                                        })
                                                                    }
                                                                </InfiniteScroll>
                                                            }
                                                        </div>
                                                        <div class="topsearchmisc-pseduo-track"></div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div id="sech-articles" className="tab-pane fade">
                                            {
                                                this.state.searchOption === 'ARTICLES' && this.state.searchData &&
                                                this.state.searchData.type === 'ARTICLES' && this.state.searchData.content &&
                                                <>
                                                    <div className="srch-filter">
                                                        <div className="results">
                                                            <i className="iEditBox" />
                                                            {this.state.searchData.totalElements} Results
                                                        </div>
                                                    </div>
                                                    <div className='srchresultScrollbar'>
                                                        <div className={'srchresultwrap people topsearchmisc-outer-wrapper'} style={{height: "260px", overflow: "auto"}}>
                                                            {
                                                                this.state.searchData.content && this.state.searchData.content.length > 0
                                                                && <InfiniteScroll
                                                                    loadMore={this.fetchMoreSearchData.bind(this)}
                                                                    getScrollParent={() => this.scrollParentArticleRef}
                                                                    hasMore={!this.state.isSearchResultLoading && !this.state.searchData.last}
                                                                    loader={<h4>Loading...</h4>}
                                                                    useWindow={false}
                                                                >
                                                                    {
                                                                        this.state.searchData.content.map((item) => {
                                                                            return <ArticleSearchItem item={item}
                                                                                key={item.postId} />
                                                                        })
                                                                    }
                                                                </InfiniteScroll>
                                                            }
                                                        </div>
                                                        <div class="topsearchmisc-pseduo-track"></div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div id="sech-feeds" className="tab-pane fade">
                                            {
                                                this.state.searchOption === 'FEEDS' && this.state.searchData &&
                                                this.state.searchData.type === 'FEEDS' && this.state.searchData.content &&
                                                <>
                                                    <div className="srch-filter">
                                                        <div className="results"><i className="iEditBox" />
                                                            {this.state.searchData.totalElements} Results
                                                        </div>
                                                    </div>
                                                    <div className='srchresultScrollbar'>
                                                        <div className={'srchresultwrap people topsearchmisc-outer-wrapper'} style={{height: "260px", overflow: "auto"}}>
                                                            {
                                                                this.state.searchData.content && this.state.searchData.content.length > 0
                                                                && <InfiniteScroll
                                                                    loadMore={this.fetchMoreSearchData.bind(this)}
                                                                    getScrollParent={() => this.scrollParentFeedsRef}
                                                                    hasMore={!this.state.isSearchResultLoading && !this.state.searchData.last}
                                                                    loader={<h4>Loading...</h4>}
                                                                    useWindow={false}
                                                                >
                                                                    {
                                                                        this.state.searchData.content.map((item) => {
                                                                            return <FeedSearchItem item={item}
                                                                                key={item.postId} />
                                                                        })
                                                                    }
                                                                </InfiniteScroll>
                                                            }
                                                        </div>
                                                        <div class="topsearchmisc-pseduo-track"></div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div id="sech-circles" className="tab-pane fade">
                                            {
                                                this.state.searchOption === 'CIRCLE' && this.state.searchData &&
                                                this.state.searchData.type === 'CIRCLE' && this.state.searchData.content &&
                                                <>
                                                    <div className="srch-filter">
                                                        <div className="results"><i className="iEditBox" />
                                                            {this.state.searchData.totalElements} Results
                                                        </div>
                                                    </div>
                                                    <div className='srchresultScrollbar'>
                                                        <div className={'srchresultwrap people topsearchmisc-outer-wrapper'} style={{height: "260px", overflow: "auto"}}>
                                                            {
                                                                this.state.searchData.content && this.state.searchData.content.length > 0
                                                                && <InfiniteScroll
                                                                    loadMore={this.fetchMoreSearchData.bind(this)}
                                                                    getScrollParent={() => this.scrollParentCircleRef}
                                                                    hasMore={!this.state.isSearchResultLoading && !this.state.searchData.last}
                                                                    loader={<h4>Loading...</h4>}
                                                                    useWindow={false}
                                                                >
                                                                    {
                                                                        this.state.searchData.content.map((item) => {
                                                                            return <CircleSearchItem item={item}
                                                                                key={item.postId} />
                                                                        })
                                                                    }
                                                                </InfiniteScroll>
                                                            }
                                                        </div>
                                                        <div class="topsearchmisc-pseduo-track"></div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div id="sech-hashtags" className="tab-pane fade">
                                            {
                                                this.state.searchOption === 'HASHTAG' && this.state.searchData &&
                                                this.state.searchData.type === 'HASHTAG' && this.state.searchData.content &&
                                                <>
                                                    <div className="srch-filter">
                                                        <div className="results"><i className="iEditBox" />
                                                            {this.state.searchData.totalElements} Results
                                                        </div>
                                                    </div>
                                                    <div className='srchresultScrollbar'>
                                                        <div className={'srchresultwrap people topsearchmisc-outer-wrapper'} style={{height: "260px", overflow: "auto"}}>
                                                            {
                                                                this.state.searchData.content && this.state.searchData.content.length > 0
                                                                && <InfiniteScroll
                                                                    loadMore={this.fetchMoreSearchData.bind(this)}
                                                                    getScrollParent={() => this.scrollParentCircleRef}
                                                                    hasMore={!this.state.isSearchResultLoading && !this.state.searchData.last}
                                                                    loader={<h4>Loading...</h4>}
                                                                    useWindow={false}
                                                                >
                                                                    {
                                                                        this.state.searchData.content.map((item) => {
                                                                            return <HashtagSearchItem item={item} key={item.postId} />
                                                                        })
                                                                    }
                                                                </InfiniteScroll>
                                                            }
                                                        </div>
                                                        <div class="topsearchmisc-pseduo-track"></div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div id="sech-groups" className="tab-pane fade">
                                            <div className="srch-filter">
                                                <div className="results">
                                                    <i className="iWN_Network_OL" />
                                                    245 Results
                                                </div>
                                            </div>
                                            <Scrollbars
                                                className={'srchresultwrap groups'}
                                                autoHide
                                                autoHeight
                                                autoHeightMin={0}
                                                autoHeightMax={260}
                                                universal={true}
                                                renderTrackHorizontal={props => <div {...props} className="track-horizontal" />}
                                                renderTrackVertical={props => <div {...props} className="track-vertical" />}
                                            >
                                                <ul>

                                                    <GroupSearchItem />

                                                </ul>
                                            </Scrollbars>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="usertoppanel">
                                <Link to="/our-solution" id="info-toggle" className="btn point active" style={{padding: '7px', lineHeight: '0'}}>
                                    <i className="iInfo" data-tip="msg to show" data-for={"helpIcon"} data-place='bottom'/>
                                    <ReactTooltip
                                        className="rtoolTip"
                                        place="bottom"
                                        id={"helpIcon"}
                                        type="dark"
                                        textColor="#00394D"
                                        effect="solid"
                                        backgroundColor="#ffffff">Help
                                    </ReactTooltip>
                                </Link>
                                <Link to={"/profile/" + (this.state.profileData && this.state.profileData.customUrl)} onClick={() => {CleverTapUtils.eventClick({}, "View Profile");}}

                                    className="userprofile btn" style={{textTransform: this.state.profileData.type !== "COMPANY" ? "capitalize" : "none"}}>
                                    <figure><img
                                        src={(this.state.profileData && this.state.profileData.profileImage && this.state.profileData.profileImage !== '') ? getCDNUrl(this.getOptimizedImage(this.state.profileData)) : (this.state.profileData.type === 'COMPANY' ? 'https://cdn.dscovr.com/images/DefaultBusiness.png' : 'https://cdn.dscovr.com/images/users1.png')}
                                        alt="" /></figure>
                                    <span>{this.state.profileData && this.state.profileData.type === 'INDIVIDUAL' ? this.state.profileData.firstName : this.state.profileData && this.state.profileData.companyName}</span>
                                </Link>

                                <Notification />

                                <div className="usermenu">
                                    <button type="button" className="btn" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false" onClick={() => this.getMenuData()}><i className="iMeatballs" /></button>
                                    <div className="dropdown-menu topDropdownMob fadeIn animated">
                                        {this.props.yearData && (this.props.yearData.status === "UPCOMING" ||  (this.props.yearData.status === "COMPLETED" && this.props.yearData.showInThreeDot)) && <Link className="dropdown-item" to={`/people-of-nature-award/${this.props.yearData.status === "UPCOMING" ? "upcoming" : "event"}/${this.props.yearData.year}`}>People of Nature Awards</Link>}
                                        <Link className="dropdown-item" to="/wenaturalists-pro">WeNaturalists Pro</Link>
                                        <a className="dropdown-item" href='javascript:void(0)' onClick={() => this.redirectToUserDashboard()}>Dashboard and Settings</a>
                                        {/* <div className="dropdown-item dropdown-submenu">
                                            <a data-toggle="dropdown" href="javascript:void(0)">Manage</a>
                                            <div className="dropdown-menu fadeIn animated">
                                                <Link className="dropdown-item" to="/manage-invitation">Manage Invitations</Link>
                                                <Link className="dropdown-item" to={`/${this.state.userData.customUrl}/studio`}>Manage Studio</Link>
                                                <Link className="dropdown-item" to="/forum-poll-draft">Drafts</Link>
                                                <Link className="dropdown-item" to="/hidden-content">Hidden Content</Link>
                                                <Link className="dropdown-item" to="/analytics">Analytics</Link>
                                            </div>
                                        </div> */}
                                        <div className="dropdown-item dropdown-submenu">
                                            <a data-toggle="dropdown" href="javascript:void(0)">About WeNaturalists</a>
                                            <div className="dropdown-menu fadeIn animated">
                                                <a className="dropdown-item" href="/about-us">About Us</a>
                                                <Link className="dropdown-item" to="/how-we-impacts">How We Impacts</Link>
                                                <Link className="dropdown-item" to="/how-it-works">How It Works</Link>
                                                <Link className="dropdown-item" to="/world-people-of-nature-day">People of Nature Day</Link>
                                                {this.state.adminAccess && 
                                                    <>
                                                        <Link className="dropdown-item" to="/admin">Admin Panel</Link>
                                                        <Link className="dropdown-item" to="/heatmap-analytics">HeatMap Analytics</Link>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <div className="dropdown-item dropdown-submenu">
                                            <a data-toggle="dropdown" href="javascript:void(0)">Terms of Use</a>
                                            <div className="dropdown-menu fadeIn animated">
                                                <Link className="dropdown-item" to="/UserAgreement">User
                                                    agreement</Link>
                                                <Link className="dropdown-item" to="/PrivacyPolicy">Privacy
                                                    Policy</Link>
                                                <Link className="dropdown-item" to="/TermsConditions">Terms &
                                                    conditions</Link>
                                                <Link className="dropdown-item" to="/CookiePolicy">Cookie Policy</Link>
                                                <Link className="dropdown-item" to="/CommunityGuidelines">Community Guidelines</Link>
                                                <Link className="dropdown-item" to="/refund-policy">Refund Policy</Link>
                                                <Link className="dropdown-item" to="/studio-terms-conditions">Studio Terms & conditions</Link>
                                            </div>
                                        </div>
                                        <div className="dropdown-item dropdown-submenu">
                                            <a data-toggle="dropdown" href="javascript:void(0)">Support</a>
                                            <div className="dropdown-menu fadeIn animated">
                                                <Link className="dropdown-item" to="/FaQ">FAQ & Support</Link>
                                                <Link className="dropdown-item" to="/Feedback">Feedback</Link>
                                                {/* {this.state.userData && this.state.userData.type && this.state.userData.type === 'COMPANY' ?
                                                    <Link className="dropdown-item" to="/organization-privacy-settings">Settings & Privacy</Link> :
                                                    <Link className="dropdown-item" to="/privacy-settings">Settings & Privacy</Link>}                                                 */}
                                                <Link className="dropdown-item" to="/signout">Sign Out</Link>
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>
                                <i className="iHamburger"
                                    style={{ display: this.props.hamburgerVisibility === false ? 'none' : '' }} />
                                <i className="iCross"
                                    style={{ display: this.props.hamburgerVisibility === false ? 'none' : '' }} />
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.pathname && this.state.pathname === 'message' ? <></> : <Chat />}
                <ProjectDetails />
            </header>

        }
    }
}

const mapStateToProps = (state) => {
    return {
        userData: JSON.parse(JSON.stringify(state.authReducer.userData)),
        personalProfileData: state.ProfileReducer.personalProfileData,
        yearData: state.WpondReducer?.yearData

    };

};

const mapDispatchToProps = (dispatch) => {
    return {
        setUserData: dispatch(setUserData(authService.getUserDetailsFromLocalStorage())),
        requestNotificationReceivedFulfilled: (value) => dispatch(requestNotificationReceivedFulfilled(value)),
        setUserProfileData: (state) => dispatch(setProfileData(state)),
        messageReceivedFulfilled: (value) => dispatch(messageReceivedFulfilled(value)),
        setWpondYearData: (state) => dispatch(setWpondYearData(state))
    };
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(withRouter(Header)));
