import React from "react";

import { NavLink, Redirect } from "react-router-dom";
import { authService } from "../auth";
import axios from "axios";
import FooterDiv from "../FooterDiv";
import * as _ from "lodash";
import CountryCode from "../country_code";
import httpService from "../AxiosInterceptors";
import Select from "react-select";
import { getUserDataFromUrlParams, isWebView, userAgentPostData } from "../commons/CommonFunctions";

httpService.setupInterceptors();

class IndividualSignUp extends React.Component {
  constructor(props) {
    super(props);
    let validation = {
      validatePassword: {
        uppercaseTest: new RegExp("^(?=.*[A-Z])"),
        minCharacterTest: new RegExp("^(?=.{8})"),
        style: { borderColor: "#b34c4c", padding: "10px" },
      },
      firstName: {},
      lastName: {},
      phone: {},
      email: {},
      password: {},
      countryCode: {},
    };

    CountryCode.sort((a, b) =>
      a.Dial > b.Dial ? 1 : b.Dial > a.Dial ? -1 : 0
    );

    this.state = {
      isLoading: false,
      token: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      validation,
      isIndianResident: true,
      password: "",
      agreeToTerms: true,
      loginSuccess: authService.isAuthenticated(),
      signUpSuccess: false,
      emailAvailable: false,
      signUpResponse: {},
      upperCaseMsg: "1 Uppercase",
      minimumPasswordCharacter:
        "Password should be 8 characters",
      errors: {},
      fields: {},
      countryCodeInfo: [],
      countryCode: [],
      username: "",
      countryCodeList: [].concat(CountryCode),
      // countryCodeInfo: '',
      charCountCheckboxStatus : false,
      upperCaseCheckboxStatus : false,
      signUpCode: "",
      signUpWithCode: false
    };
  }

  handleChangeCode = (event) => {
    this.setState({
      signUpCode: event.target.value?.trimStart().replace(/[^a-zA-Z0-9]/g, "")
    })
  }

  onCheckSignUpWithCode = (event) => {
    this.setState({
      signUpWithCode: event.target.checked
    })
  }

  newTabLink = () => {
    window.open(
      "mailto:support@wenaturalists.com",
      "_blank",
      "toolbar=yes,scrollbars=yes,resizable=yes,top=50,left=50,width=1000,height=1000"
    );
  };

  componentDidMount() {
    document.title = "Sign up to join a new avenue for Naturalists";
    window.scrollTo(0, 0);
    window.$(".selectpicker").selectpicker();
    document.title = "Sign up to join a new avenue for Naturalists";
    //signUp page conversion
    // {
    //   process.env.REACT_APP_environment === "prod" &&
    //     window.gtag("config", "AW-713421902");
    //   process.env.REACT_APP_environment === "prod" &&
    //     window.gtag("event", "conversion", {
    //       send_to: "AW-713421902/3cOhCIiR2_QBEM7ol9QC",
    //     });
    // }

    window.$("[data-toggle=popover]").popover({
      html: true,
      placement: "left",
      trigger: "focus", //click use only button click to close
      template:
        '<div class="popover signPopover" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>',
      content: function () {
        var content = window.$(this).attr("data-popover-content");
        return window.$(content).children(".popover-body").html();
      },
    });
    // window.$(document).on("click", ".react-select__option", function (event) {
    //     window.$(".popover").popover('hide');
    // });
  }

  getCountryCodeNew = (newValue, actionMeta) => {
      this.setState({ selectedOption4: newValue.value });
      this.setState({ countryCodeInfo: newValue });
      window.$(".popover").popover('hide');
  };

  getCountryCodeOptions = () => {
    let types = this.state.countryCodeList.map((option) => {
      return {
        value: option.Unicode + " +" + option.Dial,
        dial: option.Dial,
        country: option.Name,
        label: option.Unicode + " +" + option.Dial + " " + option.Name,
        name: "countrycode",
      };
    });
    return types;
  };

  countryDropDownMenu() {
    const { selectedOption4 } = this.state;
    return (
      <Select
        options={this.getCountryCodeOptions()}
        onChange={this.getCountryCodeNew}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        placeholder={"Code *"}
        name={"countrycode"}
        classNamePrefix="react-select"
        styles={{
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = "#cfe7c7";
            return {
              ...styles,
              backgroundColor: isDisabled
                ? null
                : isSelected
                ? "#cfe7c7"
                : isFocused
                ? "#cfe7c7"
                : null,
              color: isDisabled
                ? "#ccc"
                : isSelected
                ? color > 2
                  ? "#00374d"
                  : "#00374d"
                : data.color,
              cursor: isDisabled ? "not-allowed" : "default",

              ":active": {
                ...styles[":active"],
                backgroundColor:
                  !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
              },
            };
          },
        }}
      />
    );
    //    return <select className="selectpicker" data-width="fit"
    //         name="countryCode"
    //         onChange={this.onSelectCountryCode}
    //         style={this.state.validation.countryCode.style}
    //         required={true}>
    //         {this.getCountryCodeOptions()}
    //     </select>
  }

  getCountryCode = () => {
    var maxLength;
    var minLength;
    if (
      this.state.countryCodeInfo &&
      this.state.countryCodeInfo.dial === "91"
    ) {
      maxLength = 10;
      minLength = 10;
    } else {
      maxLength = 15;
      minLength = 3;
    }
    return (
      <div className="form-group">
        <div
          className="chphonefield"
          data-popover-content="#unique-id"
          data-toggle="popover"
          data-placement="left"
        >
          {this.countryDropDownMenu()}
          <input
            type="text"
            className="custom-input form-control"
            id="validationCustomUsername"
            aria-describedby="inputGroupPrepend"
            style={
              this.state.validation.phone.error
                ? this.state.validation.phone.style
                : null
            }
            value={this.state.phone}
            data-tip={
              this.state.validation.phone.error &&
              this.state.validation.phone.message
            }
            onBlur={(e) => this.validate("phone", e.target.value)}
            onChange={(e) => this.validate("phone", e.target.value)}
            minLength={minLength}
            maxLength={maxLength}
            placeholder="Phone No.*"
          />

          <span
            className="login-feedback-error mt-1"
            style={{ display: "block" }}
          >
            {this.state.errors.countryCode}
          </span>
          <span className="login-feedback-error mt-1">
            {this.state.validation.countryCode &&
              this.state.validation.countryCode.message}
          </span>
          <div id="unique-id" style={{ display: "none" }}>
            <div class="popover-body clearfix">
              <p>Type your country name to search for the country code</p>
              <div className="d-flex justify-content-end">
                <a class="popover-close">Got It</a>
              </div>
            </div>
          </div>
        </div>

        {this.state.errors && this.state.errors.phone ? (
          <span
            className="login-feedback-error mt-1"
            style={{ display: "block" }}
          >
            {this.state.errors.phone}
          </span>
        ) : null}
        {this.state.validation && this.state.validation.phone ? (
          <span className="login-feedback-error mt-1">
            {this.state.validation.phone && this.state.validation.phone.message}
          </span>
        ) : null}
      </div>
    );
  };

  sendToAddressScreen = (event) => {
    event.preventDefault();
    window.$("#emailVerifySendpop").modal("hide");
    this.props.history.push({
      pathname: "/address",
      state: {
        signUpResponse: this.state.signUpResponse.data.body,
      },
    });
  };

  analyzePassword = (value) => {
    let error = false;
    if (this.state.validation.validatePassword.uppercaseTest.test(value)) {
      // this.setState({ upperCaseMsg: "" });
      this.setState({upperCaseCheckboxStatus : true});
    } else {
      this.setState({ upperCaseMsg: "1 Uppercase" , upperCaseCheckboxStatus : false });
      error = true;
    }
    if (this.state.validation.validatePassword.minCharacterTest.test(value)) {
      // this.setState({ minimumPasswordCharacter: "" });
      this.setState({charCountCheckboxStatus : true});
    } else {
      this.setState({
        minimumPasswordCharacter:
          "Password should be 8 characters ", charCountCheckboxStatus : false
      });
      error = true;
    }
    if (error) {
      this.state.validation.password = {
        error: true,
        message: "Please enter valid Password",
        style: { borderColor: "#b34c4c" },
      };
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validateForm()) {
      this.setState({ isLoading: true });
      let postBody = {
        firstName: this.state.firstName.trim(),
        lastName: this.state.lastName.trim(),
        password: this.state.password,
        agreeToTerms: this.state.agreeToTerms,
        indianResident: this.state.isIndianResident,
        mobile: this.state.phone,
        email: this.state.email.toLowerCase().trim(),
        country: this.state.countryCodeInfo.country,
        countryISDCode: this.state.countryCodeInfo.dial,
        userAgent: userAgentPostData(),
        signUpWithCode: this.state.signUpWithCode,
        licenseCode: this.state.signUpCode,
        signUpSource: this.props.location?.state?.wpondRedirectFlag ? "WPOND" : null
      };
      axios({
        method: "post",
        url: process.env.REACT_APP_userServiceURL + "/user/signup/individual",
        headers: { "Content-Type": "application/json" },
        data: postBody,
        withCredentials: true,
      })
        .then((response) => {
          let res = response.data;
          if (res.message === "Success!") {
            let authResult = {};
            this.setState({
              username: res.body.username,
              email: res.body.email,
            });
            if (this.state.isIndianResident) {
              authResult.phone = this.state.phone;
            } else {
              this.setState({ emailAvailable: true });
              authResult.email = this.state.email;
            }
            authResult.isIndianResident = res.body.indianResidence;
            authResult.type = res.body.type;
            authResult.userDataPending = res.body.userDataPending;
            authResult.userId = res.body.userId;
            authResult.firstName = res.body.firstName;
            authResult.lastName = res.body.lastName;
            authResult.customUrl = res.body.customUrl;
            authService.setSession(authResult, response);

            if (window.ReactNativeWebView && authResult.userId) {
              window.ReactNativeWebView.postMessage(
                JSON.stringify({
                  type: "login",
                  userId: authResult.userId,
                })
              );
            }

            this.setState({ signUpResponse: response, isLoading: false });
            this.props.history.push({
                        pathname: '/signup-verify',
                        state: {
                            signUpResponse: this.state.signUpResponse.data.body
                        }
                    })

            window.$("#emailVerifySendpop").modal("show");

            // {
            //   process.env.REACT_APP_environment === "prod" &&
            //     window.gtag("config", "AW-713421902");
            //   process.env.REACT_APP_environment === "prod" &&
            //     window.gtag("event", "conversion", {
            //       send_to: "AW-713421902/Sk5zCL373eMCEM7ol9QC",
            //     });
            // }
          }
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            this.setState({
              isLoaded: true,
              isLoading: false,
              error: { message: err.response.data.message, err: err.response },
            });
            authService.clearData();
          }
        });
    }
    {
      setTimeout(() => {
        this.setState({
          error: {},
        });
      }, 5000);
    }
    {
      setTimeout(() => {
        this.setState({
          errors: {},
        });
      }, 5000);
    }
  };

  validateForm() {
    this.state.validation.firstName = {};
    this.state.validation.lastName = {};
    this.state.validation.phone = {};
    this.state.validation.email = {};
    this.state.validation.password = {};
    this.state.validation.countryCode = {};
    this.state.validation.signUpCode = {};
    let validationRequired = false;
    let errors = {};
    let formIsValid = true;
    if (this.state.firstName === "") {
      if (
        _.isUndefined(this.state.firstName) ||
        _.isEmpty((this.state.firstName || "").toString()) ||
        _.isNull(this.state.firstName)
      ) {
        errors["firstName"] = "Please enter your first name";
        this.state.validation.firstName = {
          error: true,
          style: { borderColor: "#b34c4c" },
        };
        this.setState({ isLoading: false });
        formIsValid = false;
        validationRequired = true;
      }
    } else {
      const fn = /^[a-zA-Z\s]+$/;
      if (this.state.firstName === "" || fn.test(this.state.firstName)) {
        this.setState({ firstName: this.state.firstName });
      }

      this.state.validation.firstName = {};
      if (
        _.isUndefined(this.state.firstName) ||
        _.isEmpty((this.state.firstName || "").toString()) ||
        _.isNull(this.state.firstName) ||
        this.state.firstName.trim().replace(" ", "").length > 25 ||
        this.state.firstName.trim().replace(" ", "").length < 2
      ) {
        this.state.validation.firstName = {
          error: true,
          message: "First name must have minimum 2 characters",
          style: { borderColor: "#b34c4c", padding: "10px" },
        };
        this.setState({ isLoading: false });
        formIsValid = false;
      }
    }
    if (this.state.lastName === "") {
      if (
        _.isUndefined(this.state.lastName) ||
        _.isEmpty((this.state.lastName || "").toString()) ||
        _.isNull(this.state.lastName)
      ) {
        errors["lastName"] = "Please enter your last name";
        this.state.validation.lastName = {
          error: true,
          style: { borderColor: "#b34c4c" },
        };
        this.setState({ isLoading: false });
        formIsValid = false;
        validationRequired = true;
      }
    } else {
      const ln = /^[a-zA-Z\s]+$/;
      if (this.state.lastName === "" || ln.test(this.state.lastName)) {
        this.setState({ lastName: this.state.lastName });
      }
      this.state.validation.lastName = {};
      if (
        _.isUndefined(this.state.lastName) ||
        _.isEmpty((this.state.lastName || "").toString()) ||
        _.isNull(this.state.lastName) ||
        this.state.lastName.trim().replace(" ", "").length > 25 ||
        this.state.lastName.trim().replace(" ", "").length < 2
      ) {
        this.state.validation.lastName = {
          error: true,
          message: "Last name must have minimum 2 characters",
          style: { borderColor: "#b34c4c", padding: "10px" },
        };
        this.setState({ isLoading: false });
        formIsValid = false;
      }
    }
    if (
      _.isUndefined(this.state.phone) ||
      _.isEmpty((this.state.phone || "").toString()) ||
      _.isNull(this.state.phone)
    ) {
      errors["phone"] = "Please enter your phone number";
      this.state.validation.phone = {
        error: true,
        style: { borderColor: "#b34c4c" },
      };
      this.setState({ isLoading: false });
      formIsValid = false;
      validationRequired = true;
    } else {
      const re = /^[0-9\b]+$/;
      if (this.state.phone === "" || re.test(this.state.phone)) {
        this.setState({ phone: this.state.phone });
      }

      this.state.validation.phone = {};
      if (this.state.countryCodeInfo.dial === "91") {
        const regExp = /^[6-9\b]+$/;
        if (this.state.phone === "" || regExp.test(this.state.phone)) {
          this.setState({ phone: this.state.phone });
        }
        if (
          this.state.phone.trim().replace(" ", "").length > 10 ||
          this.state.phone.trim().replace(" ", "").length < 10 ||
          !regExp.test(this.state.phone[0])
        ) {
          this.state.validation.phone = {
            error: true,
            message: "Please enter valid mobile number",
            style: { borderColor: "#b34c4c" },
          };
          this.setState({ isLoading: false });
          formIsValid = false;
        }
      }
    }
    if (
      _.isUndefined(this.state.countryCodeInfo.dial) ||
      _.isEmpty(this.state.countryCodeInfo.dial) ||
      _.isNull(this.state.countryCodeInfo.dial)
    ) {
      errors["countryCode"] = "Please select the country code";
      this.state.validation.countryCode = {
        error: true,
        style: { borderColor: "#b34c4c" },
      };
      this.setState({ isLoading: false });
      formIsValid = false;
      validationRequired = true;
    }
    if (this.state.signUpWithCode && !this.state.signUpCode) {
      errors["signUpCode"] = "Please enter valid myWeNat code";
      this.state.validation.signUpCode = {
        error: true,
        style: { borderColor: "#b34c4c" },
      };
      this.setState({ isLoading: false });
      formIsValid = false;
      validationRequired = true;
    }
    if (
      _.isUndefined(this.state.email) ||
      _.isEmpty((this.state.email || "").toString()) ||
      _.isNull(this.state.email)
    ) {
      errors["email"] = "Please enter your email address";
      this.state.validation.email = {
        error: true,
        style: { borderColor: "#b34c4c", padding: "10px" },
      };
      this.setState({ isLoading: false });
      formIsValid = false;
      validationRequired = true;
    } else {
      const email =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.setState({ email: this.state.email });
      this.state.validation.email = {};
      if (!email.test(this.state.email.trim())) {
        this.state.validation.email = {
          error: true,
          message: "Please enter a valid email address",
          style: { borderColor: "#b34c4c" },
        };
        this.setState({ isLoading: false });
        formIsValid = false;
      }
    }
    if (this.state.password === "") {
      if (
        _.isUndefined(this.state.password) ||
        _.isEmpty((this.state.password || "").toString()) ||
        _.isNull(this.state.password)
      ) {
        errors["password"] = "Please enter a password";
        this.state.validation.password = {
          error: true,
          style: { borderColor: "#b34c4c" },
        };
        this.setState({ isLoading: false });
        formIsValid = false;
        validationRequired = true;
      }
    } else {
      this.setState({ password: this.state.password });
      this.state.validation.password = {};
      let error = false;
      if (
        this.state.validation.validatePassword.uppercaseTest.test(
          this.state.password
        )
      ) {
        // this.setState({ upperCaseMsg: "" });
        this.setState({upperCaseCheckboxStatus : true});
        
      } else {
        this.setState({ upperCaseMsg: "1 Uppercase" ,upperCaseCheckboxStatus : false });
        error = true;
      }
      if (
        this.state.validation.validatePassword.minCharacterTest.test(
          this.state.password
        )
      ) {
        // this.setState({ minimumPasswordCharacter: "" });
        this.setState({charCountCheckboxStatus : true});
        
      } else {
        this.setState({
          minimumPasswordCharacter:
            "Password should be 8 characters", charCountCheckboxStatus : false
        });
        error = true;
      }
      if (error) {
        this.state.validation.password = {
          error: true,
          message: "Please enter valid Password",
          style: { borderColor: "#b34c4c" },
        };
        this.setState({ isLoading: false });
        formIsValid = false;
      }
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  validate = (fieldName, value) => {
    let isValid = true;
    switch (fieldName) {
      case "firstName":
        const fn = /^[a-zA-Z\s]+$/;
        // if (value === "" || fn.test(value)) {
        //   this.setState({ firstName: value });
        // }
        this.setState({ firstName: value });

        this.state.validation.firstName = {};
        if (
          _.isUndefined(value) ||
          _.isEmpty((value || "").toString()) ||
          _.isNull(value) ||
          value.trim().replace(" ", "").length > 25 ||
          value.trim().replace(" ", "").length < 2
        ) {
          this.state.validation.firstName = {
            error: true,
            message: "First name must have minimum 2 characters",
            style: { borderColor: "#b34c4c", padding: "10px" },
          };
        }
        break;

      case "lastName":
        const ln = /^[a-zA-Z\s]+$/;
        // if (value === "" || ln.test(value)) {
        //   this.setState({ lastName: value });
        // }
        this.setState({ lastName: value });
        this.state.validation.lastName = {};
        if (
          _.isUndefined(value) ||
          _.isEmpty((value || "").toString()) ||
          _.isNull(value) ||
          value.trim().replace(" ", "").length > 25 ||
          value.trim().replace(" ", "").length < 2
        ) {
          this.state.validation.lastName = {
            error: true,
            message: "Last name must have minimum 2 characters",
            style: { borderColor: "#b34c4c", padding: "10px" },
          };
        }
        break;

      case "phone":
        const re = /^[0-9\b]+$/;
        if (value === "" || re.test(value)) {
          this.setState({ phone: value });
        }

        this.state.validation.phone = {};
        if (this.state.countryCodeInfo.dial === "91") {
          const regExp = /^[6-9\b]+$/;
          if (
            _.isUndefined(value) ||
            _.isEmpty((value || "").toString()) ||
            _.isNull(value) ||
            value.trim().replace(" ", "").length > 10 ||
            value.trim().replace(" ", "").length < 10
          ) {
            this.state.validation.phone = {
              error: true,
              message: "Please enter your phone number",
              style: { borderColor: "#b34c4c" },
            };
          } else if (!regExp.test(value[0])) {
            this.state.validation.phone = {
              error: true,
              message: "Please enter valid phone number",
              style: { borderColor: "#b34c4c" },
            };
          }
        }
        break;

      case "email":
        const email =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.setState({ email: value });
        this.state.validation.email = {};
        if (
          _.isUndefined(value.trim()) ||
          _.isEmpty((value.trim() || "").toString()) ||
          _.isNull(value.trim()) ||
          !email.test(value.trim())
        ) {
          this.state.validation.email = {
            error: true,
            message: "Please enter a valid email address",
            style: { borderColor: "#b34c4c", padding: "10px" },
          };
        }
        break;
      case "password":
        this.setState({ password: value && value.replace(/ /g,"") });
        this.state.validation.password = {};
        this.analyzePassword(value.replace(/ /g,""));
        break;

      default:
        isValid = true;
        break;
    }
    return isValid;
  };

  render() {
    const { error } = this.state;

    if (this.state.loginSuccess) {
      return <Redirect to="/" />;
    } else {
      return (
        <section className="signup-wrapper">
          <div className="signup-container pt-3">
            <a href="/" className="goback">
              {" "}
              Go Back
            </a>
            <div className="signup-box text-center">
              <div className="signup-boxinn">
                {!isWebView() ? (
                  <a href="/">
                    <img
                      src="https://cdn.dscovr.com/images/logoWeNaturalists.svg"
                      alt=""
                      className="logotop"
                    />
                  </a>
                ) : (
                  <a href="/login">
                    <img
                      src="https://cdn.dscovr.com/images/logoWeNaturalists.svg"
                      alt=""
                      className="logotop"
                    />
                  </a>
                )}
                <h1 className="signup-header-text">Join Our Community</h1>
                <div className="signup-form-container">
                  <form onSubmit={this.handleSubmit} id="create-individual-form" name="create-individual-form">
                    <div className="row">
                      <div className="col-md-6 col-xs-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="default-caps custom-input form-control"
                            style={
                              this.state.validation.firstName.error
                                ? this.state.validation.firstName.style
                                : null
                            }
                            value={this.state.firstName}
                            data-tip={
                              this.state.validation.firstName.error &&
                              this.state.validation.firstName.message
                            }
                            onBlur={(e) =>
                              this.validate("firstName", e.target.value)
                            }
                            onChange={(e) =>
                              this.validate("firstName", e.target.value)
                            }
                            autoComplete="given-name"
                            placeholder="First name *"
                            maxLength="25"
                          />
                          <span
                            className="login-feedback-error mt-1"
                            style={{ display: "block" }}
                          >
                            {this.state.errors.firstName}
                          </span>
                          <span
                            style={{ display: "block" }}
                            className="login-feedback-error mt-1"
                          >
                            {this.state.validation.firstName &&
                              this.state.validation.firstName.message}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6 col-xs-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="default-caps custom-input form-control"
                            style={
                              this.state.validation.lastName.error
                                ? this.state.validation.lastName.style
                                : null
                            }
                            value={this.state.lastName}
                            data-tip={
                              this.state.validation.lastName.error &&
                              this.state.validation.lastName.message
                            }
                            onBlur={(e) =>
                              this.validate("lastName", e.target.value)
                            }
                            onChange={(e) =>
                              this.validate("lastName", e.target.value)
                            }
                            autoComplete="family-name"
                            placeholder="Last name *"
                            maxLength="25"
                          />
                          <span
                            className="login-feedback-error mt-1"
                            style={{ display: "block" }}
                          >
                            {this.state.errors.lastName}
                          </span>
                          <span className="login-feedback-error mt-1">
                            {this.state.validation.lastName &&
                              this.state.validation.lastName.message}
                          </span>
                        </div>
                      </div>
                    </div>
                    {this.getCountryCode()}
                    <div className="form-group">
                      <div className="chphonefield email">
                        <input
                          type="text"
                          className="custom-input form-control"
                          id="email"
                          style={
                            this.state.validation.email
                              ? this.state.validation.email.style
                              : null
                          }
                          value={this.state.email}
                          data-tip={
                            this.state.validation.email.error &&
                            this.state.validation.email.message
                          }
                          onBlur={(e) => this.validate("email", e.target.value)}
                          onChange={(e) =>
                            this.validate("email", e.target.value)
                          }
                          aria-describedby="inputGroupPrepend"
                          placeholder="Email *"
                        />
                        <span
                          className="login-feedback-error mt-1"
                          style={{ display: "block" }}
                        >
                          {this.state.errors.email}
                        </span>
                        <span className="login-feedback-error mt-1">
                          {this.state.validation.email &&
                            this.state.validation.email.message}
                        </span>
                      </div>
                    </div>

                    <div className="form-group passfield">
                      <div className="passfieldinn">
                        <input
                          type="password"
                          className="custom-input form-control"
                          //maxLength="15"
                          style={
                            this.state.validation.password.error
                              ? this.state.validation.password.style
                              : null
                          }
                          value={this.state.password}
                          data-tip={
                            this.state.validation.password.error &&
                            this.state.validation.password.message
                          }
                          onBlur={(e) =>
                            this.validate("password", e.target.value)
                          }
                          onChange={(e) =>
                            this.validate("password", e.target.value)
                          }
                          aria-describedby="inputGroupPrepend"
                          placeholder="Password *"
                        />
                        <span
                          className="passtoggle"
                          style={{
                            display: "block",
                          }}
                        >
                          Show
                        </span>
                        <small className="pass-tooltip">
                            <div className={!this.state.charCountCheckboxStatus ? 'passCheckList' : 'passCheckList check'}>
                                {this.state.minimumPasswordCharacter}
                            </div>

                            <div className={!this.state.upperCaseCheckboxStatus ? 'passCheckList' : 'passCheckList check'}>
                                {this.state.upperCaseMsg}
                            </div>
                        </small>
                      </div>
                      {/*<small>(Must be 8 characters or more)</small>*/}
                      {/* <small className="mt-1">
                        {" "}
                        {this.state.minimumPasswordCharacter}{" "}
                        {this.state.upperCaseMsg}
                      </small> */}

                      <small
                        className="login-feedback-error mt-1"
                        style={{
                          display: "block",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {this.state.errors.password}
                      </small>
                      {/* <small
                        className="login-feedback-error mt-1"
                        style={{
                          display: "block",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {this.state.validation.password &&
                          this.state.validation.password.message}
                      </small> */}
                    </div>                    
                    {/* <div className="form-group">
                      <a tabIndex="0" className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input"
                              id={'m07'}
                              checked={this.state.signUpWithCode}
                              onClick={this.onCheckSignUpWithCode}/>
                            <label className="custom-control-label" htmlFor="m07">Sign up with code</label>
                        </a>
                    </div> */}
                    {this.state.signUpWithCode && 
                      <div className="form-group">
                        <div className="chphonefield email">
                          <input
                            type="text"
                            className="custom-input form-control text-uppercase"
                            id="signUpCode"
                            style={this.state.validation.signUpCode ? this.state.validation.signUpCode.style : null}
                            value={this.state.signUpCode}
                            onChange={this.handleChangeCode}
                            aria-describedby="inputGroupPrepend"
                            placeholder="Sign up with code"
                          />
                          <span className="login-feedback-error mt-1">
                            {this.state.errors.signUpCode}
                          </span>
                        </div>
                      </div>
                    }
                    {error && (
                      <span
                        className="login-feedback-error mt-1"
                        style={{ display: "block" }}
                      >
                        {" "}
                        {error.message}{" "}
                      </span>
                    )}

                    <p className="login-paragraph-text">
                      I accept the WeNaturalists{" "}
                      <strong>
                        <NavLink
                          to="/TermsConditions"
                          className="privacy-link"
                          target="_blank"
                        >
                          {" "}
                          Terms & Conditions
                        </NavLink>
                        ,{" "}
                        <NavLink
                          to="/UserAgreement"
                          className="privacy-link"
                          target="_blank"
                        >
                          {" "}
                          User Agreement
                        </NavLink>
                        ,{" "}
                        <NavLink
                          to="/PrivacyPolicy"
                          className="privacy-link"
                          target="_blank"
                        >
                          Privacy Policy
                        </NavLink>
                        , and{" "}
                        <NavLink
                          to="/CookiePolicy"
                          className="privacy-link"
                          target="_blank"
                        >
                          Cookie Policy.
                        </NavLink>
                      </strong>
                    </p>

                    <button
                      type="submit"
                      className="login-btn btn btn-primary mt-1"
                      disabled={this.state.isLoading}
                    >
                      Agree & Join
                    </button>
                  </form>

                  <p className="login-paragraph-text already">
                    Already a member?{" "}
                    <NavLink to="/login" className="signup-link">
                      Log in
                    </NavLink>
                  </p>
                  <div className="extbutt">
                    {/*<div><NavLink to="/signup-company" className="btn">Join as a Company</NavLink>
                                    </div>*/}
                    {isWebView() ? (
                      <div>
                        <a
                          href="javascript:void(0)"
                          onClick={() => this.newTabLink()}
                        >
                          <span>Contact Support</span>
                        </a>
                      </div>
                    ) : (
                      <div>
                        <a
                          href="mailto:support@wenaturalists.com"
                          target="_blank"
                        >
                          Contact Support
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div
              id="emailVerifySendpop"
              className="modal fade dgpopup"
              data-backdrop="static"
              data-keyboard="false"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="emailVerifySendpop">
                      <div className="icoImg">
                        <i className="iSend-Message" />
                      </div>
                      <h3>Verification email Send.</h3>
                      <p>
                        Hi {this.state.username}! We sent a Verification link on
                        your <br />
                        email id : {this.state.email}
                      </p>

                      <div className="btnwrap">
                        <a
                          href="javascript:void(0)"
                          onClick={this.sendToAddressScreen}
                          className="btnProceed"
                        >
                          Proceed
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <FooterDiv />
          </div>
        </section>
      );
    }
  }
}

export default IndividualSignUp;
